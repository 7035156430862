
import React, { useEffect, useState } from "react";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import moment from "moment";
import ServicesApi from "../../../services/servicesAPI";

export default function ViewPaymentReport(props) {
    const { appointmentId } = props;
    const [paymentData, setPaymentData] = useState(null)
    const { getPaymentsByAppointmentId } = ServicesApi();

    useEffect(() => {
        paymentDetails()
    }, []);

    const paymentDetails = () => {
        getPaymentsByAppointmentId(appointmentId)
            .then((res) => {
                setPaymentData(res)
            })
    }

    return (
        <div className="patientDataStyle">
            <>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className="font_weight" align="left">Mode of Payment</TableCell>
                                <TableCell className="font_weight" align="left">Amount</TableCell>
                                <TableCell className="font_weight" align="left">Payment Date</TableCell>
                                <TableCell className="font_weight" align="left">Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paymentData && paymentData.map((item, index) => {
                                return (
                                    <>
                                        {item ? (
                                            <TableRow key={index}>
                                                <TableCell align="left">
                                                    {item.paymentMethod}
                                                </TableCell>

                                                <TableCell align="left">
                                                    {item.amount / 100}
                                                </TableCell>

                                                <TableCell align="left">
                                                    {moment(item.created_at).format("DD-MM-YYYY")}
                                                </TableCell>

                                                <TableCell align="left">
                                                    {item.status === "Completed" ? <span className="greenColor"> {item.status}</span> : item.status}
                                                </TableCell>

                                            </TableRow>
                                        ) : null}
                                    </>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        </div>
    )
}