import React, { useState, useEffect } from 'react';
import { KEYID, SECRETEKEY } from '../../../config';
import { Button, Modal } from "react-bootstrap";
import AppointmentsApi from '../../../services/AppointmentsApi';
import ReportApi from '../../../services/ReportApi';
import ServicesApi from '../../../services/servicesAPI';
import { MainButtonInput } from '../../../mainComponent/mainButtonInput';
import RenderRazorpay from '../RenderRazorpay/RenderRazorPay';
import AuthApi from '../../../services/AuthApi';

export default function CompletePayment(props) {
    const { reportId, appointmentId, doctorId, onSubmit } = props;
    const [total, setTotal] = useState(0);
    const [partialAmount, setPartialAmount] = useState(0);
    const [patientFees, setPatientFees] = useState(0);
    const [extraAmount, setExtraAmount] = useState(0);
    const [paidFees, setPaidFees] = useState(0);
    const [extraFees, setExtraFees] = useState(0);
    const [getServices, setGetServices] = useState(null);
    const [doctorName, setDoctorName] = useState(null);
    const [bookingInfo, setBookingInfo] = useState(null);
    const [followupDate, setFollowupDate] = useState(null);
    const [show, setShow] = useState(false);
    const [error, setError] = useState(false);
    const { getDrInfo } = AuthApi();
    const [displayRazorpay, setDisplayRazorpay] = useState(false);
    const { UpdateStatusBookingdata, createPDF, getPaymentData } = AppointmentsApi();
    const { getDentalServicesPrescription, getMedicineReport } = ReportApi();
    const { paymentCapture, payment, getPaymentsByAppointmentId } = ServicesApi();
    const [orderDetails, setOrderDetails] = useState({
        orderId: null,
        currency: null,
        amount: null,
        payId: null,
    });

    // Get initial data from APIs
    useEffect(() => {
        getServicesData();
        paymentdata();
        getDoctorPersonalDetails();
        getPartialPayments();
        getMedicalReportData();
    }, []);

    // Fetch payment data for the booking (including the total fees)
    const paymentdata = () => {
        getPaymentData(appointmentId)
            .then((data) => {
                setBookingInfo(data[0]);
                setPatientFees(data[0]?.total || 0);  // Safe access to patientFees
            });
    };

    // Get doctor details (doctor's name)
    const getDoctorPersonalDetails = () => {
        getDrInfo({ doctorId })
            .then((result) => {
                setDoctorName(result.result[0]?.name); // Safe access to doctorName
            });
    };

    // Fetch dental services and calculate total service fees
    const getServicesData = () => {
        getDentalServicesPrescription({ reportId })
            .then((result) => {
                if (result) {
                    setGetServices(result);
                    const totalAmt = result.reduce((acc, curr) => acc + parseInt(curr.fees), 0);
                    setTotal(totalAmt);
                }
            });
    };

    const getMedicalReportData = () => {
        getMedicineReport({ reportId })
            .then((result) => {
                if (result[0].new_follow_up) {
                    setFollowupDate(result[0].new_follow_up)
                } else {
                    setFollowupDate(null)
                }
            })
    }
    // Fetch partial payments and extra amounts
    const getPartialPayments = () => {
        getPaymentsByAppointmentId(appointmentId)
            .then((result) => {
                const partialPayments = result.reduce(
                    (acc, curValue) => acc + parseInt(curValue.amount / 100),
                    0
                );
                const extraPayment = result.reduce((initialValue, value) => {
                    // Ensure that extraAmount is a valid number
                    return initialValue + (isNaN(parseInt(value.extraAmount)) ? 0 : parseInt(value.extraAmount));
                }, 0);
                setPartialAmount(partialPayments);
                setExtraAmount(extraPayment);
            });
    };

    // Update paid fees
    const handlePaidFeesValue = (e) => {
        setPaidFees(e.target.value);
    };

    // Update extra fees
    const handleExtraFeesValue = (e) => {
        setExtraFees(e.target.value);
    };
    // Calculate total amount (including patient fees, service fees, extra fees)
    const totalAmount = Number(patientFees) + Number(extraFees);
    const pendingAmount = totalAmount - (Number(partialAmount) + Number(paidFees)); // Pending amount calculation

    // Handle modal to confirm payment
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    // Handle order creation for payments
    const handlePaymentOrderData = () => {
        if (paidFees) {
            const bodyData = {
                amount: paidFees * 100,
                appointmentId: appointmentId,
                transactionId: `pay_${appointmentId}`,
            };

            payment(bodyData)
                .then((data) => {
                    const status = (pendingAmount > 0 || (followupDate && followupDate > new Date())) ? bookingInfo.status : "Completed";
                    const bodyData = {
                        status: status,
                        payment: pendingAmount > 0 ? "Pending" : "Done",
                        medicalReportId: reportId,
                        paymentId: data.id,
                        total: Number(patientFees) + Number(extraFees)
                    };
                    UpdateStatusBookingdata({ appointmentId }, bodyData);

                    const details = {
                        totalAmount: Number(patientFees) + Number(extraFees),
                        pendingamount: pendingAmount,
                        doctorId: doctorId,
                        extraFees: extraFees,
                        orderId: data.order_id,
                        paymentId: `pay_${appointmentId}`,
                        status: pendingAmount > 0 ? "Partial" : "Completed",
                        paymentMethod: "cash",
                        refId: appointmentId,
                        signature: appointmentId,
                        _id: data.id,
                    };

                    paymentCapture(details);
                    onSubmit(); // Trigger the parent callback
                });
        } else {
            setError("Please paid atleast minimum amount")
        }
    };

    // Handle Razorpay order creation
    const handleCreateOrder = async () => {
        const bodyData = {
            amount: paidFees * 100,
            appointmentId,
            transactionId: `${appointmentId}_paycash`,
        };

        payment(bodyData).then((data) => {
            if (data?.order_id) {
                setOrderDetails({
                    payId: data.id,
                    orderId: data.order_id,
                    currency: data.currency,
                    amount: data.amount,
                });
                setDisplayRazorpay(true);
            }
        });
    };

    return (
        <>
            {bookingInfo && (
                <div className='paymentInput'>
                    <label>Consultation Fees: </label>
                    <span className='ml-2'>{bookingInfo.fees}</span>
                </div>
            )}

            {getServices && getServices.map((item, i) => (
                <div key={i} className='paymentInput'>
                    <label>{item.service_name}: </label>
                    <span className='ml-2'>{item.fees}</span>
                </div>
            ))}
            <div className='border-payment' />

            {bookingInfo && (
                <div className='paymentInput'>
                    <label className='mt-2'>Total Amount: </label>
                    <span className="ml-2 mt-2">{bookingInfo.total}</span>
                </div>
            )}

            <div className='paymentInput'>
                <label className='mt-2'>Paid Amount: </label>
                <span className="ml-2 mt-2">{partialAmount}</span>
            </div>

            <div className='paymentInput'>
                <label className='mt-2'>Pending Amount:</label>
                <span className="ml-2 mt-2">{pendingAmount}</span>
            </div>

            <div className='border-payment' />

            <div className='paymentInput'>
                <label className='mt-2'>Extra Fees: </label>
                <input
                    type="text"
                    value={extraFees}
                    onChange={handleExtraFeesValue}
                    className="payment otherInput"
                    name="extraFees"
                />
            </div>

            <div className='paymentInput'>
                <label className='mt-2'>Enter Amount you pay now: </label>
                <input
                    type="text"
                    value={paidFees}
                    onChange={handlePaidFeesValue}
                    className="payment otherInput"
                    name="OtherFees"
                />
            </div>
            <div className="validation">{error}</div>
            <div className='paymentInput'>
                <label className='mt-2'>Pending Amount:</label>
                <span className="ml-2 mt-2">{pendingAmount}</span>
            </div>

            <div className='border-payment' />

            <div className='row'>
                <div className="mt-3 text-center">
                    <MainButtonInput onClick={handleShow}>Cash Payment</MainButtonInput>
                </div>

                <div className="mt-3 ml-2 text-center">
                    <div className="disabled-div">
                        <label>Online Payment</label>
                    </div>
                </div>

                {displayRazorpay && (
                    <RenderRazorpay
                        payId={orderDetails.payId}
                        amount={orderDetails.amount}
                        currency={orderDetails.currency}
                        orderId={orderDetails.orderId}
                        keyId={KEYID}
                        keySecret={SECRETEKEY}
                        doctorName={doctorName}
                        reportId={reportId}
                        appointmentId={appointmentId}
                        doctorId={doctorId}
                        refId={appointmentId}
                        pendingamount={pendingAmount}
                        totalAmount={patientFees + total + extraAmount}
                    />
                )}
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Are you sure?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="alert modalColor">Did you receive complete payment?</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" className='appColor' onClick={handlePaymentOrderData}>Yes</Button>
                    <Button variant="default" className='lightbuttonColor borderStyle' onClick={handlePaymentOrderData}>Due</Button>
                    <Button variant="default" className='lightbuttonColor borderStyle' onClick={handleClose}>No</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
