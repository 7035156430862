import React, { useEffect, useState } from "react";
import { Bar } from 'react-chartjs-2';
import ServicesApi from "../../../services/servicesAPI";
import { THEME_COLOR } from '../../../config'
export default function YearlyReport(props) {
    const { doctorId, payments } = props;
    const [yearlyData, setYearlyData] = useState(null);
    
    const {
        getyearlyPayment,
    } = ServicesApi()

    useEffect(() => {
        handlePayment()
    }, [payments]);

    const handlePayment = () => {
        const year = payments.year || null;
        getyearlyPayment(doctorId, year)
            .then((result) => {
                if (Array.isArray(result)) {
                    const yearlyData = transformYearlyPaymentData(result)
                    setYearlyData(yearlyData)
                } else {

                }
            })
    }

    const transformYearlyPaymentData = (paymentData) => {
        const monthlyTotals = new Array(12).fill(0);
        paymentData.forEach(payment => {
            // Parse the date
            const date = new Date(payment.created_at);
            const monthIndex = date.getMonth(); // 0 = January, 1 = February, etc.
            // Convert amount to number and add to the corresponding month
            const amount = parseFloat(payment.amount)/ 100;
            if (!isNaN(amount)) {
                monthlyTotals[monthIndex] += amount;
            }
        });

        return {
            labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            datasets: [{
                label: 'Monthly Payments',
                data: monthlyTotals,
                fill: true,
                borderColor: { THEME_COLOR },
                backgroundColor: '#e1e8ed',
                tension: 0.1
            }]
        };
    };

    return (
        <div>
            <h5>Yearly Payments</h5>
            {yearlyData ? (
                <Bar
                    height={110}
                    data={yearlyData}
                />
            ) : (
                <p>Please select year...</p>
            )}
        </div>
    )
}