import React, { useEffect, useState, useRef } from "react";
import { setHelperData } from "../../../recoil/atom/setHelperData";
import { Wrapper } from '../../../mainComponent/Wrapper';
import { useRecoilState } from "recoil";
import { Link, useParams } from 'react-router-dom';
import { MainNav } from '../../../mainComponent/mainNav';
import UserLinks from '../partial/uselinks';
import { Button, Modal } from "react-bootstrap";
import { MainSelect } from "../../../mainComponent/mainSelect";
import ReactPaginate from "react-paginate";
import LabWorkApi from "../../../services/LabWorkApi";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LabWorkView from "./LabWorkView";
import moment from 'moment';
import Loader from '../Loader';
import { Drawer, IconButton, Typography, useMediaQuery } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const exportToCSV = (csvData) => {
    // Extracting the column headers from the first object
    const columns = Object.keys(csvData[0]);

    // Generating the CSV header
    let csvContent = columns.join(",") + "\n";

    // Generating CSV rows
    csvData.forEach(item => {
        const row = columns.map(column => {
            // Handle null or undefined values
            const value = item[column] !== null && item[column] !== undefined ? item[column].toString().replace(/"/g, '""') : '';
            return `"${value}"`;
        }).join(",");
        csvContent += row + "\n";
    });

    // Creating the CSV file
    const encodedUri = encodeURI("data:text/csv;charset=utf-8," + csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "data.csv");
    document.body.appendChild(link);
    link.click();
};


export default function LabWorkList() {
    const { doctorId } = useParams();
    const [helpersData] = useRecoilState(setHelperData)
    const [labWorkData, setLabWorkData] = useState(null)
    const [labs, setLabs] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0);
    const [selectedLab, setSelectedLab] = useState(null);
    const [selectedDateRange, setSelectedDateRange] = useState([null, null]);
    const [showData, setShowData] = useState(false);
    const [labWorkId, setLabWorkId] = useState(false);
    const isMobile = useMediaQuery('(max-width:  768px)')
    const [open, setOpen] = useState(false);
    const paginationRef = useRef(currentPage);
    const labRef = useRef(currentPage);
    const dateRangeRef = useRef(currentPage);
    const pageSize = 5;
    const { getlabworkbydoctorId } = LabWorkApi()

    useEffect(() => {
        getLabWorkData(currentPage, selectedLab, selectedDateRange);
    }, [])

    const getLabWorkData = (currentPage, selectedLab, selectedDateRange) => {
        setIsLoading(true);
        let filters = {
            page: currentPage,
            pageSize: pageSize,
            ...(selectedDateRange[0] && { startDate: selectedDateRange[0] }),
            ...(selectedDateRange[1] && { endDate: selectedDateRange[1] }),
            ...(selectedLab && { selectedLab: selectedLab }),
        }
        getlabworkbydoctorId(doctorId, filters)
            .then((result) => {
                const lablist = result.result
                setTotalPages(result.totalPages)
                setLabWorkData(result.data)
                if (result.result) {
                    const res = lablist.filter(function (item, index) {
                        return index === lablist.findIndex((obj) => {
                            if (item.labname === obj.labname)
                                return item
                        })
                    })
                    setLabs(res)
                } else {
                    return (
                        <div className="clinicHistory font_weight" align='center' >
                            Lab work records unavailable
                        </div>
                    )
                }
            })
            .catch((error) => {
                console.error("Failed to fetch lab work data", error);
                // setLabs([]); 
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handlePageClick = (data) => {
        paginationRef.current = data.selected + 1
        setCurrentPage(data.selected + 1)
        getLabWorkData(data.selected + 1, selectedLab, selectedDateRange)
    }

    const handleLabChange = (e) => {
        labRef.current = e.target.value
        setSelectedLab(e.target.value);
        getLabWorkData(currentPage, e.target.value, selectedDateRange);
    };

    // Function to handle date range change
    const handleDateRangeChange = (newValue) => {
        if (newValue[0] && newValue[1]) {
            dateRangeRef.current = newValue
            setSelectedDateRange(newValue);
            getLabWorkData(currentPage, selectedLab, newValue)
        }
    };

    const handleDataClose = () => {
        setShowData(false)
        setOpen(false)
    }

    const handleDataShow = (id) => {
        setLabWorkId(id)
        setShowData(true)
        setOpen(true)
    }

    const handleExport = () => {
        if (labWorkData) {
            const labWork = labWorkData.map(lab => ({
                WorkName: lab.workname,
                WorkType: lab.worktype,
                Price: lab.price,
                LabName: lab.labname,
                Contact: lab.labcontact,
                Patient: lab.patient,
                Clinic: lab.clinic,
                ToothNumber: lab.toothnumber,
                Comment: lab.comments
            }));
            exportToCSV(labWork);
        } else {
            return null
        }
    };

    return (
        <>
            <Wrapper>
                <MainNav>
                    <div className="clearfix row">
                        <div className="width50">
                            <ul className="clearfix">
                                <li className='float-none' style={{ fontSize: 'inherit' }}>Report</li>
                            </ul>
                        </div>
                    </div>
                </MainNav>

                <div className='row'>
                    <div className='width_16'>
                        <div className='dash row'>
                            <UserLinks
                                doctorId={doctorId}
                                helperId={helpersData._id}
                                accessModule={helpersData.access_module}
                            />
                        </div>
                    </div>

                    <div className='width_84'>
                        {isLoading ?
                            <div className='loader-container'>
                                <Loader />
                            </div>
                            :
                            <div className='common_box'>
                                <>
                                    <div className="white-box">
                                        <div className='row' align='left'>
                                            <div className='mr-4'>
                                                <label className='mb-2 font_weight' value="lab">Select Lab</label>
                                                <div className='mt-2'>
                                                    <MainSelect value={selectedLab} onChange={handleLabChange}>
                                                        <option value="">Select Lab</option>
                                                        {labs.map((item, index) => (
                                                            <option key={index} value={item.labname} className="form-control">{item.labname}</option>
                                                        ))}
                                                    </MainSelect>
                                                </div>
                                            </div>
                                            <div className='mr-4'>
                                                <label className="font_weight" value="month">Select Date Range</label>
                                                <div>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DemoContainer components={['DateRangePicker']}>
                                                            <DateRangePicker
                                                                value={selectedDateRange}
                                                                onChange={handleDateRangeChange}
                                                                localeText={{ start: 'Start', end: 'End' }} />
                                                        </DemoContainer>
                                                    </LocalizationProvider>
                                                </div>
                                            </div>
                                            <div>
                                                <label className="font_weight" value="month">Export</label>
                                                <div className='mt-2'>
                                                    <Button
                                                        type="submit"
                                                        onClick={handleExport}
                                                        variant="default"
                                                        className='lightbuttonColor  mr-3 btn_sub'>
                                                        <i className="icon-download-1" style={{ fontSize: 15 }} />
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {labWorkData && labWorkData.length > 0 ?
                                        <>
                                            <TableContainer component={Paper}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell className="font_weight" align="left">Work Name</TableCell>
                                                            <TableCell className="font_weight" align="left">Work Type</TableCell>
                                                            <TableCell className="font_weight" align="left">Lab Name</TableCell>
                                                            <TableCell className="font_weight" align="left">Lab Contact</TableCell>
                                                            <TableCell className="font_weight" align="left">Given Date</TableCell>
                                                            <TableCell className="font_weight" align="left">View Details</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {labWorkData && labWorkData.map((item, index) => {
                                                            return (
                                                                <TableRow key={index}>
                                                                    <TableCell align="left">
                                                                        {item.workname}
                                                                    </TableCell>

                                                                    <TableCell align="left">
                                                                        {item.worktype}
                                                                    </TableCell>

                                                                    <TableCell align="left">
                                                                        {item.labname}
                                                                    </TableCell>

                                                                    <TableCell align="left">
                                                                        {item.labcontact}
                                                                    </TableCell>

                                                                    <TableCell align="left">
                                                                        {moment(item.givendate).format("DD-MM-YYYY")}
                                                                    </TableCell>

                                                                    <TableCell align="left">
                                                                        <Link onClick={() => handleDataShow(item._id)}>
                                                                            <VisibilityIcon style={{ fontSize: 20 }} />
                                                                        </Link>
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <div className='mt-5'>
                                                <ReactPaginate
                                                    breakLabel="..."
                                                    nextLabel="Next >"
                                                    onPageChange={handlePageClick}
                                                    pageRangeDisplayed={5}
                                                    pageCount={totalPages}
                                                    previousLabel="< Previous"
                                                    renderOnZeroPageCount={null}
                                                    marginPagesDisplayed={2}
                                                    containerClassName="pagination "
                                                    pageClassName="page-item"
                                                    pageLinkClassName="page-link"
                                                    previousClassName="page-item"
                                                    previousLinkClassName="page-link"
                                                    nextClassName="page-item"
                                                    nextLinkClassName="page-link"
                                                    activeClassName="active"
                                                    forcePage={currentPage - 1}
                                                />
                                            </div>
                                        </> :
                                        <div className="clinicHistory font_weight" align='center' >
                                            Lab work records unavailable
                                        </div>
                                    }
                                </>
                            </div>
                        }
                    </div>

                </div>
            </Wrapper>
            {isMobile ? (
                <Drawer anchor="bottom" open={open} onClose={handleDataClose}>
                    <div className='drawerTitle underline' >
                        <Typography variant="h6">View Details</Typography>
                        <IconButton onClick={handleDataClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <div className='p-4'>
                        <LabWorkView labWorkId={labWorkId} onSubmit={handleDataClose} />
                    </div>
                </Drawer>
            ) : (
                <div className="modalbtn">
                    <Modal show={showData} onHide={handleDataClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>View Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <LabWorkView labWorkId={labWorkId} onSubmit={handleDataClose} />
                        </Modal.Body>
                    </Modal>
                </div>
            )}
        </>
    )
}