export const validateForm = (data) => {
    const errors = {};
    let formIsValid = true;

    //validation for inventory
    if (data.addInwardData) {
        if (!data.selectedItem && !data.addInwardData.itemName) {
            errors.itemName = "Item name is required";
            formIsValid = false;
        }

        if (!data.selectedManufacturer && !data.addInwardData.manufacturerName) {
            errors.manufacturerName = "Manufacturer name is required";
            formIsValid = false;
        }

        if (!data.selectedcategory && !data.addInwardData.category) {
            errors.category = "Category is required";
            formIsValid = false;
        }

        if (!data.selectedSubcategory && !data.addInwardData.subCategory) {
            errors.subCategory = "Subcategory is required";
            formIsValid = false;
        }

        if (!data.addInwardData.quantity) {
            errors.quantity = "Quantity & select is required";
            formIsValid = false;
        }

        if (!data.addInwardData.cost) {
            errors.cost = "Cost is required";
            formIsValid = false;
        }
        if (!data.addInwardData.spoc) {
            errors.spoc = "Spoc is required";
            formIsValid = false;
        }

        if (!data.saveClinic || !data.saveClinic.clinicName) {
            errors.clinicName = "Select clinic";
            formIsValid = false;
        }

        if (!data.inwardDate) {
            errors.inwardDate = "Select inward date";
            formIsValid = false;
        }

        if (!data.expiryDate) {
            errors.expiryDate = "Select expiry date";
            formIsValid = false;
        }
    }
    //validation for Labwork
    if (data.labWokData) {
        if (!data.labWokData.name) {
            errors.name = "Work name is required";
            formIsValid = false;
        }
        if (!data.saveWork.worktype) {
            errors.worktype = "Select Work type";
            formIsValid = false;
        }
        if (!data.saveTooth || data.saveTooth.length === 0) {
            errors.toothNumbers = "Select Tooth numbers";
            formIsValid = false;
        }

        if (!data.saveClinic || !data.saveClinic.clinicName) {
            errors.clinicName = "Select Clinic";
            formIsValid = false;
        }
        if (!data.savepatient || !data.savepatient) {
            errors.patientName = "Select patient";
            formIsValid = false;
        }
        if (!data.saveLab || !data.saveLab.labname) {
            errors.labName = "Select Lab";
            formIsValid = false;
        }

    }
    //patient details form
    if (data.updatePatientData) {
        if (!data.updatePatientData.name) {
            errors.name = "Enter name";
            formIsValid = false;
        }
        if (!data.updatePatientData.age) {
            errors.age = "Enter age";
            formIsValid = false;
        }
        const gender = data.saveGender || data.updatePatientData.gender;
        if (!gender) {
            errors.gender = "Enter gender";
            formIsValid = false;
        }
    }
    //for add LAb 
    if (data.lab) {
        if (!data.lab.name) {
            errors.name = "Enter name";
            formIsValid = false;
        }
        if (!data.lab.contact) {
            errors.contact = "Enter contact";
            formIsValid = false;
        }
    }
    //for add work
    if (data.work) {
        if (!data.work.worktype) {
            errors.worktype = "Enter worktype";
            formIsValid = false;
        }
        if (!data.work.price) {
            errors.price = "Enter price";
            formIsValid = false;
        }
    }
    //Add treatment
    if (data.treatment) {
        if (!data.treatment.name) {
            errors.name = "Enter Treatment";
            formIsValid = false;
        }
        if (!data.treatment.fees) {
            errors.fees = "Enter Fees";
            formIsValid = false;
        }
    }
    //Add medicine
    if (data.medicine) {
        if (!data.medicine.medicineName) {
            errors.medicineName = "Enter medicine name";
            formIsValid = false;
        }
        if (!data.medicine.mg) {
            errors.mg = "Enter Mg";
            formIsValid = false;
        }
    }


    //Add Packages
    if (data.packageData) {
        if (!data.packageData.packagename) {
            errors.packagename = "Enter package name ";
            formIsValid = false;
        }
        if (!data.packageData.shortdescription) {
            errors.shortdescription = "Enter short description";
            formIsValid = false;
        }
        if (!data.selectedItem) {
            errors.selectedItem = "Select plan";
            formIsValid = false;
        }

        if (!data.selectedDays) {
            errors.selectedDays = "Select Days";
            formIsValid = false;
        }
        if (!data.file) {
            errors.file = "Select atleast one file";
            formIsValid = false;
        }

    }
    // Package type
    if (data.packages) {
        // if (!data.savefeatures) {
        //     errors.savefeatures = "Enter package name ";
        //     formIsValid = false;
        // }
        if (!data.packages.name) {
            errors.packageName = "Enter package name";
            formIsValid = false;
        }
        if (!data.packages.inclusions) {
            errors.inclusions = "Enter inclusion";
            formIsValid = false;
        }
        if (!data.packages.exclusions) {
            errors.exclusions = "Enter Exclusion";
            formIsValid = false;
        }
        if (!data.packages.price) {
            errors.price = "Enter price";
            formIsValid = false;
        }
    }

    // for add helper form 
    if (data.loginData) {
        if (!data.loginData.name) {
            errors.name = "Name is required";
            formIsValid = false;
        }

        if (!data.loginData.password) {
            errors.password = "Password is required";
            formIsValid = false;
        }

        if (!data.loginData.mobile || !/^\d{10}$/.test(data.loginData.mobile)) {
            errors.mobile = "Valid mobile number is required";
            formIsValid = false;
        }

        if (data.selectedModule.length === 0) {
            errors.modules = "At least one module must be selected";
            formIsValid = false;
        }
    }
    //start consultation general info
    if (data.changeData) {
        if (!data.changeData.age) {
            errors.age = "Enter age";
            formIsValid = false;
        }
        if (!data.changeData.weight) {
            errors.weight = "Enter weight";
            formIsValid = false;
        }
        if (!data.changeData.height) {
            errors.height = "Enter height";
            formIsValid = false;
        }
    }
    //start consultation symptoms
    if (data.saveSymptoms) {
        if (!data.saveSymptoms || data.saveSymptoms.length === 0) {
            errors.symptoms = "Select symptoms";
            formIsValid = false;
        }
    }
    //start consultation Treatment
    if (data.saveServices && data.saveTooth) {
        if (!data.saveServices || !data.saveServices.name) {
            errors.treatment = "Select treatment";
            formIsValid = false;
        }
        if (!data.saveServices || !data.saveServices.fees) {
            errors.fees = "Fees is Mandatory";
            formIsValid = false;
        }
        if (!data.date || !data.date) {
            errors.date = "Select date";
            formIsValid = false;
        }
        if (!data.saveTooth.length === 0 || !data.saveTooth) {
            errors.tooth = "Select Tooth";
            formIsValid = false;
        }
        if (!data.selectedValue || !data.selectedValue) {
            errors.doctor = "Select doctor";
            formIsValid = false;
        }
    }
    //start consultation Medicine
    if (data.values) {
        if (!data.medicineSave) {
            errors.medicine = "Select medicine";
            formIsValid = false;
        }
        if (!data.values.duration) {
            errors.duration = "Enter duration";
            formIsValid = false;
        }
        if (!data.values.mg) {
            errors.mg = "Enter mg";
            formIsValid = false;
        }
        if (!data.saveMealData) {
            errors.mealData = "Select meal";
            formIsValid = false;
        }
        if (data.selectedSchedule.length === 0) {
            errors.frequency = "Please select at least one frequency";
            formIsValid = false;
        }
    }

    //start consultation lab prescription
    if (data.saveLabData) {
        if (!data.saveLabData || !data.saveLabData.test_name) {
            errors.labTest = "Select lab test";
            formIsValid = false;
        }
    }

    return { formIsValid, errors };
};