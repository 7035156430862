import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Link, useNavigate, useParams } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useRecoilState } from "recoil";
import { setDoctorId } from '../../../recoil/atom/setDoctorId';
import { setHelperData } from '../../../recoil/atom/setHelperData';
import { Wrapper } from '../../../mainComponent/Wrapper';
import { MainNav } from '../../../mainComponent/mainNav';
import UserLinks from '../../Dashboard-card/partial/uselinks';
import TreatmentFollowUp from './TreatmentFollowup';
import TreatmentDoctor from './treatmentDoctor';

function TabPanel(props) {
    const { children, value, index } = props;
    return (
        <div>
            {value === index && (
                <Box div={6}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export default function AssignDoctorTreatment() {
    const { doctorId } = useParams();
    // const [doctorId, setDoctorsId] = useRecoilState(setDoctorId)
    const [helpersData, setHelpersData] = useRecoilState(setHelperData)
    const { state } = useLocation()
    const navigate = useNavigate()
    //for tab
    const [value, setValue] = useState(0);
    const handleChange = (e, newValue) => {
        e.preventDefault();
        setValue(newValue);
    };

    function changeTab(tabIndex) {
        setValue(tabIndex);
    }

    const goBack = () => {
        navigate(-1)
    }

    return (
        <Wrapper>
            <MainNav>
                <ul className="clearfix">
                    <li>
                        {/* <Link to={`/appointments/${doctorId}`}> */}
                        <Link onClick={goBack}>
                            <i className="arrow_back backArrow" title="back button"></i>
                        </Link>
                    </li>
                    <li
                        className='float-none'
                        style={{ fontSize: 'inherit' }}>
                        Treatments
                    </li>
                </ul>
            </MainNav>
            <div className='row'>
                <div className='width_16'>
                    <div className='dash '>
                        <UserLinks
                            doctorId={doctorId}
                            helperId={helpersData._id}
                            accessModule={helpersData.access_module}
                        />
                    </div>
                </div>
                <div className='width_84'>
                    <div className="common_box">
                        <div className="white-box pb-5">
                            <Paper square>
                                <Tabs value={value} variant='scrollable' onChange={handleChange}
                                    indicatorColor="primary"
                                    textColor="primary">
                                    <Tab label="Treatments" />
                                    <Tab label="Follow up" />
                                </Tabs>
                            </Paper>
                            <div className="tablecontent">
                                <TabPanel value={value} index={0}>
                                    <TreatmentDoctor
                                        doctorId={doctorId}
                                        onChange={() => changeTab(1)}
                                    />
                                </TabPanel>

                                <TabPanel value={value} index={1}>
                                    <TreatmentFollowUp
                                        fees={state}
                                        doctorId={doctorId}
                                    />
                                </TabPanel>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Wrapper >
    )
}