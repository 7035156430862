import axios from "axios";
import { API } from "../config";

export default function TouristAppointmentsApi() {

    const touristAppointmentsDetails = async (data) => {
        try {
            const result = await axios.post(`${API}/get-medical-tourism`, data);
            return result.data;
        }
        catch (err) {
            return err
        }
    }

    const getPackages = async (data) => {
        try {
            const result = await axios.post(`${API}/get-packages`, data);
            return result.data;
        }
        catch (err) {
            return err
        }
    }

    const getTouristAppointmnetById = async (touristId) => {
        try {
            const result = await axios.get(`${API}/gettouristappointmentbyid/${touristId}`)
            return result.data
        }
        catch (err) {
            return err
        }
    }

    const getActivities = async () => {
        try {
            const result = await axios.get(`${API}/get-activities`)
            return result.data
        }
        catch (err) {
            return err
        }
    }

    const saveTourismPackages = async (bodyData) => {
        try {
            const result = await axios.post(`${API}/save-packages`, bodyData)
            return result.data
        }
        catch (err) {
            return err
        }
    }

    const removePackage = async (id) => {
        try {
            const result = await axios.delete(`${API}/deletepackage/${id}`)
            return result.data
        }
        catch (err) {
            return err
        }

    }

    const updatePackage = async (packageId, bodyData) => {
        try {
            const result = await axios.post(`${API}/updatepackage/${packageId}`, bodyData)
            return result.data
        }
        catch (err) {
            return err
        }
    }

    const getPackageById = async (packageId) => {
        try {
            const result = await axios.get(`${API}/get-package-details/${packageId}`)
            return result.data
        }
        catch (err) {
            return err
        }
    }

    const packagestatus = async (packageId, checked) => {
        try {
            const result = await axios.post(`${API}/packagestatus/${packageId}`, checked)
            return result.data
        }
        catch (err) {
            return err
        }
    }

    const packagefeatures = async (features) => {
        try {
            const result = await axios.post(`${API}/features`, features)
            return result.data
        }
        catch (err) {
            return err
        }
    }

    const getfeatures = async () => {
        try {
            const result = await axios.get(`${API}/features`)
            return result.data
        }
        catch (err) {
            return err
        }
    }

    //packages plans API
    const AddPackageTypes = async (packageData) => {
        try {
            const result = await axios.post(`${API}/packageTypes`, packageData)
            return result.data
        }
        catch (err) {
            return err
        }
    }

    const GetAllPackageTypes = async (packageData) => {
        try {
            const result = await axios.get(`${API}/packageTypes`, packageData)
            return result.data
        }
        catch (err) {
            return err
        }
    }

    const UpdatePackageTypes = async (packageId, packageData) => {
        try {
            const result = await axios.post(`${API}/packageTypes/${packageId}`, packageData)
            return result.data
        }
        catch (err) {
            return err
        }
    }

    const GetPackageTypeById = async (packageId) => {
        try {
            const result = await axios.get(`${API}/packageTypes/${packageId}`)
            return result.data
        }
        catch (err) {
            return err
        }
    }

    return {
        touristAppointmentsDetails,
        getTouristAppointmnetById,
        getPackages,
        getActivities,
        saveTourismPackages,
        removePackage,
        updatePackage,
        getPackageById,
        packagestatus,

        packagefeatures,
        getfeatures,

        AddPackageTypes,
        GetAllPackageTypes,
        UpdatePackageTypes,
        GetPackageTypeById
    }
}

