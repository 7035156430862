import React, { useEffect, useState } from "react";
import TreatmentApi from "../../../services/TreatmentApi";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import moment from 'moment';
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import dayjs from 'dayjs';

export default function TreatmentFollowUp(props) {
    const { doctorId } = props
    const { fetchTreatmentbyOwnId, updateTreatmentStatus, updatefollowupDate } = TreatmentApi()
    const [getServices, setGetServices] = useState(null);
    const [showComplete, setShowComplete] = useState(false);
    const [treatmentId, setTreatmentId] = useState('')

    useEffect(() => {
        fetchTreatment()
    }, []);

    const fetchTreatment = () => {
        fetchTreatmentbyOwnId(doctorId)
            .then((res) => {
                setGetServices(res.followUpData)
            })
    }

    const handleCompleteShow = (id) => {
        setTreatmentId(id)
        setShowComplete(true)
    }

    const handleCompleteClose = () => setShowComplete(false)

    const handleDone = (treatmentId) => {
        const status = {
            status: "Completed"
        }
        updateTreatmentStatus(treatmentId, status)
        const date = {
            type: "done"
        }
        updatefollowupDate(treatmentId, date)
        setShowComplete(false)
    }
    return (
        <>
            {getServices ?
                <div className='margin_top_15'>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align='center'><span className='patientModal'>Index</span></TableCell>
                                    <TableCell align='center'><span className='patientModal'>Treatment Name</span></TableCell>
                                    <TableCell align='center'><span className='patientModal'>Fees</span></TableCell>
                                    <TableCell align='center'><span className='patientModal'>Plan Date</span></TableCell>
                                    <TableCell align='center'><span className='patientModal'>folllowup Date</span></TableCell>
                                    <TableCell align='center'><span className='patientModal'>View</span></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {getServices && getServices.map((item, i) => {
                                    return (
                                        <TableRow key={i}>
                                            <TableCell align='center'>{i + 1}</TableCell>
                                            <TableCell align='center'>{item.treatmentName}</TableCell>
                                            <TableCell align='center'>{item.treatmentPrice}</TableCell>
                                            <TableCell align='center'>{(moment(item.planDate).format('YYYY-MM-DD'))}</TableCell>
                                            <TableCell align='center'>{(moment(item.followUp).format('YYYY-MM-DD'))}</TableCell>
                                            <TableCell>
                                                <div className="mt-2">
                                                    <Link
                                                        to="#"
                                                        onClick={() => handleCompleteShow(item._id)}
                                                        className="editbutton">
                                                        Done
                                                    </Link>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                : null}

            <Modal show={showComplete} onHide={handleCompleteClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Are you sure?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="alert modalColor">
                        You want to complete this Treatment.
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" className='appColor' onClick={() => handleDone(treatmentId)}>
                        Yes
                    </Button>
                    <Button variant="default" className="borderStyle" onClick={handleCompleteClose}>
                        No
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}