import React, { useEffect, useState } from "react";
import { Link, useNavigate, } from "react-router-dom";
import { useRecoilState } from "recoil";
import { setHelperData } from "../recoil/atom/setHelperData";
import UserLinks from "../doctor/Dashboard-card/partial/uselinks";
import { GiHamburgerMenu } from "react-icons/gi";
import { setDoctorId } from "../recoil/atom/setDoctorId";
import { setNewPatientId } from "../recoil/atom/setNewPatientId";
import { setReportsId } from "../recoil/atom/setReportId";

export default function Header() {
    const [showMenu, setShowMenu] = useState(false)
    const [doctorId, setDoctor] = useRecoilState(setDoctorId);
    const [helpersData, setHelpersData] = useRecoilState(setHelperData)
    const [patientId, setPatientsId] = useRecoilState(setNewPatientId)
    const [report, setReportId] = useRecoilState(setReportsId)
    const navigate = useNavigate();

    useEffect(() => {
        const savedDoctorId = localStorage.getItem('doctorId');
        if (savedDoctorId) {
            setDoctor(savedDoctorId); // Set the doctorId from localStorage to Recoil state
        }
    }, [setDoctor]);

    const handleLogout = (e) => {
        e.preventDefault();
        // Clear session on logout
        localStorage.removeItem('doctorId');
        localStorage.removeItem("helpersData");
        localStorage.removeItem("patientId");
        localStorage.removeItem("reportId");

        setDoctor('')
        setHelpersData('')
        setPatientsId('')
        setReportId('')
        navigate("/")
    }

    return (
        <header style={{ zIndex: '2' }} className="header_sticky">
            <div className="hamburger-menu">
                <Link to='#'
                    onClick={() => setShowMenu(!showMenu)}
                    className="btn_mobile"
                >
                    {doctorId ?
                        <>
                            <GiHamburgerMenu color='#209fd6' />

                            <div className={showMenu ? null : "dash "}>
                                <UserLinks
                                    doctorId={doctorId}
                                    helperId={helpersData._id}
                                    accessModule={helpersData.access_module}
                                />
                            </div>
                        </>
                        : null}
                </Link>
            </div>
            <div className="notification-bell container">
                <div className="row">
                    <div className="col-6">
                        <Link to={"https://fly4smiles.com/"}>
                            <div id="logo_home" align='left'>
                                <img className='appLogo' src='/images/fly4smile.png' alt="Something Went Wrong" />
                            </div>
                        </Link>
                    </div>
                    <div className=" col-6">
                        <nav className="main-menu" id="menu">
                            <ul className="d-flex">
                                {doctorId ?
                                    <>
                                        <li className="font_weight fontSize ">
                                            <Link onClick={(e) => handleLogout(e)}>
                                                Logout
                                            </Link>
                                        </li>
                                    </>
                                    : null
                                }
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </header>
    )
}