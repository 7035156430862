import { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { MainButtonInput } from "../../../mainComponent/mainButtonInput";
import AuthApi from "../../../services/AuthApi";
import { ShowLoginOtp } from "./showLoginOtp";

export default function ForgotDrMpin() {
    //for show otp input
    const [mobile, setMobile] = useState("");
    const [loginData, setLoginData] = useState([]);
    const [isError, setIsError] = useState(false);
    const [showOTP, setShowOTP] = useState(false);
    const [message, setMessage] = useState(false)
    const { ForgetMpinLogin } = AuthApi();

    const getOTPSection = (e) => {
        e.preventDefault()
        if (mobile.length < 10) {
            setIsError("Mobile number must be 10 digits")
        }
        else {
            ForgetMpinLogin({ mobile })
                .then(response => {
                    if (response.data.isLoggedIn === true) {
                        // alert(response.data.otp)
                        setIsError(false)
                        setShowOTP(true)
                        setMessage(true)
                    }
                    else {
                        setIsError('Entered mobile number is not register')
                    }
                    let item = response.data
                    setLoginData(item)
                })
        }
    };

    return (
        <main>
            <div className="bg_color_2">
                <div className="container margin_60_35">
                    <div id="login-2">
                        <h1>Reset Mpin</h1>
                        <form >
                            <div className=" clearfix">
                                <div className=" last">
                                    <div className="row">
                                        <lable className="mb-2 font_weight">Mobile Number</lable>
                                        <div className="col-md-12 mb-2">
                                            <input
                                                name="mobile"
                                                value={mobile.mobile}
                                                maxLength={10}
                                                pattern="[+-]?\d+(?:[.,]\d+)?"
                                                onChange={(e) => setMobile(e.target.value)}
                                                placeholder="Phone Number (+XX)"
                                                className="form-control"
                                            />
                                            {message && (<div align='right' className="mt-1 sendotp-message"> OTP is sent to the mobile number</div>)}
                                        </div>
                                        {showOTP === true ?
                                            <>
                                                <ShowLoginOtp loginData={loginData} />
                                                <Outlet />
                                            </>
                                            : <div className="">
                                                <MainButtonInput onClick={getOTPSection}>Go</MainButtonInput>
                                            </div>
                                        }
                                    </div>
                                    <div className="validation mb-2 ml-3">
                                        {isError}
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className="mr-2 appcolor" align="right">
                            <Link to='/'>Already have account</Link>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}