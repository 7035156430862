import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { Drawer, IconButton, Typography, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Toaster from '../../Toaster';
import { MainButtonInput } from '../../../mainComponent/mainButtonInput';
import { SecondaryButtonInput } from '../../../mainComponent/secondaryButtonInput';
import CreateTreatment from '../Treatment/CreateTreatment';
import { Modal } from 'react-bootstrap';
import { setAppointmentId } from '../../../recoil/atom/setAppointmentId';
import TreatmentApi from '../../../services/TreatmentApi';
import GetAllTreatments from './getAllTreatments';
import { setFetchTreatment } from '../../../recoil/atom/setFetchTreatment';
import Loader from '../../Dashboard-card/Loader';

export default function DentalTreatmentPriscription(props) {
    const { onChange, reportId, doctorId } = props;
    const isMobile = useMediaQuery('(max-width: 768px)');
    const [showData, setShowData] = useState(false);
    const [getServices, setGetServices] = useRecoilState(setFetchTreatment);
    const { downloadTreatemntPDF, createTreatmentPDF, fetchTreatmentbyAppointmentId } = TreatmentApi();
    const [appointmentId, setappointmentId] = useRecoilState(setAppointmentId);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getServicesData = () => {
            setLoading(true)
            fetchTreatmentbyAppointmentId(appointmentId)
                .then((result) => {
                    if (result) {
                        setGetServices(result)
                        createTreatmentPDF(appointmentId)
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        }
        if (appointmentId) {
            getServicesData()
        }
    }, [appointmentId]);

    const handleDataClose = () => {
        setShowData(false);
    };

    const handleDataShow = () => {
        setShowData(true);
    };

    const downloadPdf = () => {
        downloadTreatemntPDF(appointmentId).then((result) => {
            let alink = document.createElement('a');
            alink.href = result;
            alink.setAttribute('target', '_blank');
            alink.download = 'report.pdf';
            alink.click();
        });
    };

    return (
        <>
            <div className="float-right row">
                <div className="text-left mt-2">
                    <MainButtonInput onClick={handleDataShow}>Create Treatments</MainButtonInput>
                </div>
                <div className="text-left mt-2 ml-2">
                    <MainButtonInput onClick={downloadPdf}>Print</MainButtonInput>
                </div>
                <div className="text-left mt-2 ml-2">
                    <SecondaryButtonInput onClick={onChange}>Next</SecondaryButtonInput>
                </div>
            </div>

            <div className="row float-right">
                <Toaster />
            </div>

            {loading === true ?
                <div className='loader-container'>
                    <Loader />
                </div> :
                <>
                    <GetAllTreatments
                        getServices={getServices}
                    />
                </>
            }
            {
                isMobile ? (
                    <Drawer anchor="top" open={showData} onClose={handleDataClose}>
                        <div className="drawerTitle underline">
                            <Typography variant="h6">Create Treatment</Typography>
                            <IconButton onClick={handleDataClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <div className="p-4">
                            <CreateTreatment
                                reportId={reportId}
                                doctorId={doctorId}
                                onSubmit={handleDataClose}
                                onChange={onChange}
                            />
                        </div>
                    </Drawer>
                ) : (
                    <Modal show={showData} onHide={handleDataClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Create Treatment</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <CreateTreatment
                                reportId={reportId}
                                doctorId={doctorId}
                                onSubmit={handleDataClose}
                                onChange={onChange}
                            />
                        </Modal.Body>
                    </Modal>
                )
            }
        </>
    );
}
