import React, { useState } from 'react';
import { Outlet, useParams, useNavigate } from "react-router-dom";
import UserLinks from '../partial/uselinks';
import { setHelperData } from '../../../recoil/atom/setHelperData';
import { useRecoilState } from "recoil";
import { Wrapper } from '../../../mainComponent/Wrapper';
import { MainNav } from '../../../mainComponent/mainNav';
import TouristAppointment from './TouristAppointments';
import { MainTabs } from '../../../mainComponent/mainTabs';
import { TabPanel } from '../../../common/tabpanel';
import PackageList from './PackageList';
import { Button, Modal } from 'react-bootstrap';
import { PakageType } from './packageTypes';
import CloseIcon from '@mui/icons-material/Close';
import { Drawer, IconButton, useMediaQuery } from '@mui/material';
import PackagePlansList from './PackagePlansList';

export default function Packages() {
    const { doctorId } = useParams();
    const [helpersData, setHelpersData] = useRecoilState(setHelperData)
    const [showData, setShowData] = useState(false);
    const [value, setValue] = useState(0);
    const [activeModal, setActiveModal] = useState();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const isMobile = useMediaQuery('(max-width:768px)')
    const navigate = useNavigate();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const AddPackage = () => {
        navigate(`addpackage`)
    }

    const handleDataShow = () => {
        setShowData(true)
    }

    const handleDataClose = () => {
        setShowData(false)
        setDrawerOpen(false)
    }

    const openDrawerWithModal = (modalType, id) => {
        setActiveModal(modalType);
        setDrawerOpen(true);
    };

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const renderModalContent = () => {
        switch (activeModal) {
            case 'packageView':
                return <PakageType
                    onSubmit={handleDataClose}
                />
            default:
                return null;
        }
    };
    return (
        <Wrapper>
            <MainNav>
                <div className="clearfix row">
                    <div className="width50">
                        <ul className="clearfix">
                            <li
                                className='float-none'
                                style={{ fontSize: 'inherit' }} >
                                Tourist Appointments
                            </li>
                        </ul>
                    </div>
                    <div className="width50 row justifyContent">
                        <Button
                            className='appColor mr-3 mt-2 btn_sub'
                            type="submit"
                            onClick={() => isMobile ? openDrawerWithModal('packageView') : handleDataShow()}>
                            Package Plans
                        </Button>
                        <Button
                            className='appColor mt-2 btn_sub'
                            type="submit"
                            onClick={AddPackage}>
                            Add Tourism Package
                        </Button>
                    </div>
                </div>
            </MainNav>
            <div className="row ">
                <div className='width_16'>
                    <div className='dash row'>
                        <UserLinks
                            doctorId={doctorId}
                            helperId={helpersData._id}
                            accessModule={helpersData.access_module}
                        />
                    </div>
                </div>
                <div className='width_84 common_box'>
                    <MainTabs
                        value={value}
                        onChange={handleChange}
                        label="Tourism Packages"
                        label1="Tourist Appointments"
                        label2="Package Plans"
                    >
                    </MainTabs>

                    <TabPanel value={value} index={0}>
                        <PackageList onChange={() => setValue(0)} doctorId={doctorId} />
                    </TabPanel>

                    <TabPanel value={value} index={1}>
                        <TouristAppointment doctorId={doctorId}/>
                    </TabPanel>

                    <TabPanel value={value} index={2}>
                        <PackagePlansList doctorId={doctorId}/>
                    </TabPanel>
                </div>
            </div>
            <Outlet />

            {isMobile ?
                <Drawer anchor="bottom" open={drawerOpen} onClose={handleDrawerToggle}>
                    <div className='p-4'>
                        <div align='right'>
                            <IconButton onClick={handleDrawerToggle}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <div className='p-2'>
                            {renderModalContent()}
                        </div>
                    </div>
                </Drawer>
                :
                <Modal show={showData} onHide={handleDataClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Tourist Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <PakageType
                            doctorId={doctorId}
                            onSubmit={handleDataClose}
                        />
                    </Modal.Body>
                </Modal>
            }
        </Wrapper>

    )
}