
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Toaster = () => {
    return (
        <ToastContainer
            position="top-center"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={true}
            rtl={false}
            style={{ top: '80px' }}
            theme="light"
        />
    )
}
export const showToast = (message) => {
    toast.success(message);
};

export default Toaster