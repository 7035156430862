const MainTextArea =(props)=>{
    return(
        <div className="form-group">
            <textarea 
                type={props.type} 
                name={props.name} 
                maxLength={props.maxLength} 
                pattern={props.pattern} 
                className="form-control" 
                value={props.value} 
                onChange={props.onChange} 
                placeholder={props.placeholder}
                accept={props.accept}
                required={props.required}
                multiple={props.multiple}
                disabled={props.disabled}
            />
            {props.children}
        </div>    
    )
}
export {MainTextArea}
