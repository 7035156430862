import React, { useState, useEffect } from 'react';
import { MainButtonInput } from "../../../mainComponent/mainButtonInput";
import { MainInput } from '../../../mainComponent/mainInput';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Link } from 'react-router-dom';
import { THEME_COLOR } from '../../../config';
import TouristAppointmentsApi from '../../../services/TouristAppointmentsApi';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { MainTextArea } from '../../../mainComponent/maintextarea';

function UpdatePackageType(props) {
    const { doctorId, packageId } = props;
    const [packages, setPackages] = useState([]);
    const [features, setFeatures] = useState([]);
    const [savefeatures, setSaveFeatures] = useState([]);
    const [inputFeature, setInputFeature] = useState([]);
    const { getfeatures, UpdatePackageTypes, packagefeatures, GetPackageTypeById } = TouristAppointmentsApi()

    useEffect(() => {
        getPackagePlanData()
        getFeaturesData();
    }, [])

    const getFeaturesData = () => {
        getfeatures()
            .then((result) => {
                setFeatures(result)
            })
    }
    const getPackagePlanData = () => {
        GetPackageTypeById(packageId)
            .then((result) => {
                setPackages(result)
                setSaveFeatures(result.features)
            })
    }
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setPackages({ ...packages, [name]: value });
    };

    const handFeaturesType = (event, selectedValue) => {
        event.preventDefault()
        setSaveFeatures(selectedValue)
    }

    const addInputBox = () => {
        const value = [...inputFeature, []]
        setInputFeature(value)
    }

    const handleFeatureInput = (onChangeValue, i) => {
        const inputData = [...inputFeature]
        inputData[i] = onChangeValue.target.value;
        setInputFeature(inputData)
    }

    async function handlePackageClick(e) {
        e.preventDefault();
        if (inputFeature) {
            savefeatures.push(...inputFeature)
        }
        const packageData = {
            doctorId: doctorId,
            features: savefeatures,
            packageName: packages.packageName,
            inclusions: packages.inclusions,
            exclusions: packages.exclusions,
            packagePrice: packages.packagePrice
        }
        UpdatePackageTypes(packageId, packageData)
            .then(() => {
                inputFeature.map((item, i) => {
                    const other = {
                        "featureName": item,
                    }
                    packagefeatures(other)
                    return item
                })
            })
        props.onSubmit()
    }

    return (
        <form encType='multipart/form-data' onSubmit={handlePackageClick}>
            <div className="col-lg-12">
                <div>
                    <label className="font_weight">Package Name</label>
                    <MainInput
                        type="text"
                        name="packageName"
                        onChange={handleInputChange}
                        value={packages.packageName}
                        placeholder="Package Name" >
                    </MainInput>
                </div>

                <label className="font_weight">Select Features</label>

                <div className='row'>
                    <div className="mb-3 col-lg-11">
                        <Autocomplete
                            id={features._id}
                            disablePortal={true}
                            disableClearable
                            disableCloseOnSelect
                            value={savefeatures}
                            onChange={handFeaturesType}
                            // getOptionLabel={(data) => `${data.featureName}`}
                            // options={features}
                            options={features.map((n) => `${n.featureName}`)}
                            // renderOption={(props, option) => (<Box component="li" sx={{ mr: 2, flexShrink: 0 }} {...props}>{option.featureName}</Box>)}
                            renderInput={(params) =>
                                <TextField {...params}
                                    label="features"
                                />}
                            multiple={true}
                        />
                    </div>
                    <div className="col-lg-1">
                        <Link to="#" onClick={() => addInputBox()}>
                            <AddCircleIcon style={{ fontSize: 25, color: { THEME_COLOR }, marginLeft: 5, marginTop: 5 }} />
                        </Link>
                    </div>
                </div>

                <div className="col-lg-12">
                    {
                        inputFeature.map((data, i) => {
                            return (
                                <div key={i} >
                                    <MainInput
                                        type="text"
                                        onChange={(e) => handleFeatureInput(e, i)}
                                        // value={packages.name}
                                        placeholder="add feature" >
                                    </MainInput>
                                </div>
                            )
                        })
                    }
                </div>

                <div>
                    <label className="font_weight">Inclusions</label>
                    <MainTextArea
                        type="text"
                        name="inclusions"
                        onChange={handleInputChange}
                        value={packages.inclusions}
                        placeholder="Write here" >
                    </MainTextArea>
                </div>

                <div>
                    <label className="font_weight">Exclusions</label>
                    <MainTextArea
                        type="text"
                        name="exclusions"
                        onChange={handleInputChange}
                        value={packages.exclusions}
                        placeholder="Write here" >
                    </MainTextArea>
                </div>

                <div>
                    <label className="font_weight">Package Price</label>
                    <MainInput
                        type="text"
                        name="packagePrice"
                        onChange={handleInputChange}
                        value={packages.packagePrice}
                        placeholder="Package Price" >
                    </MainInput>
                </div>

                <div className="text-center  p-2 add_top_30">
                    <MainButtonInput>Save</MainButtonInput>
                </div>
            </div>
        </form>
    )
}
export { UpdatePackageType }
