import React, { useState } from "react";
import { Link } from "react-router-dom";
import { MainButtonInput } from "../mainComponent/mainButtonInput";
import { MainInput } from "../mainComponent/mainInput";
import { useRecoilState } from "recoil";
import UserLinks from "../doctor/Dashboard-card/partial/uselinks";
import { setHelperData } from "../recoil/atom/setHelperData";
import { MainNav } from "../mainComponent/mainNav";
import PatientApi from "../services/PatientApi";
import { Wrapper } from "../mainComponent/Wrapper";
import { setDoctorId } from "../recoil/atom/setDoctorId";
import { ShowPatientOtp } from "./showPatientOtp";

export default function ForgetPatientMpin() {
    //for show otp input
    const [patientId, setPatientId] = useState([])
    const [mobile, setMobile] = useState("");
    const [isError, setIsError] = useState(false);
    const [doctorId, setdoctorId] = useRecoilState(setDoctorId);
    const [helpersData, setHelpersData] = useRecoilState(setHelperData)
    const [loginData, setLoginData] = useState([])
    const { validLoginPatient } = PatientApi();
    const [showOTP, setShowOTP] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault()
        if (mobile.length < 10) {
            setIsError("Mobile number must be 10 digits")
        }
        else {
            validLoginPatient({ mobile })
                .then(response => {
                    if (response.data.isLoggedIn === true) {
                        // alert(response.data.otp)
                        setPatientId(response.data._id)
                        setIsError(false)
                        setShowOTP(true)
                    }
                    else {
                        setIsError('Entered mobile number is not register')
                    }
                    let item = response.data
                    setLoginData(item)
                })
        }
    };

    return (
        <Wrapper>
            <MainNav>
                <ul className="clearfix">
                    <li>
                        <Link to={`/appointments/${doctorId}/bookappointment`}>
                            <i className="arrow_back backArrow" title="back button" />
                        </Link>
                    </li>
                    <li className='float-none' style={{ fontSize: 'inherit' }}>
                        Reset Password
                    </li>
                </ul>
            </MainNav>
            <div className="row ">
                <div className='width_16'>
                    <div className='dash row'>
                        <UserLinks
                            doctorId={doctorId}
                            helperId={helpersData._id}
                            accessModule={helpersData.access_module}
                        />
                    </div>
                </div>
                <div className="width_84 common_box">
                    <div className="">
                        <div className="container margin_60_35">
                            <div id="login-2">
                                <h1>Reset Password</h1>
                                <form >
                                    <div className=" clearfix">
                                        <div className="last">
                                            <div className="row">
                                                <lable className='mb-2 font_weight'>Mobile Number</lable>
                                                <div className="col-md-12">
                                                    <MainInput
                                                        name="mobile"
                                                        value={mobile.mobile}
                                                        type="text"
                                                        maxLength={10}
                                                        minLength={10}
                                                        pattern="[+-]?\d+(?:[.,]\d+)?"
                                                        onChange={(e) => setMobile(e.target.value)}
                                                        placeholder="Phone Number (+XX)" >
                                                    </MainInput>
                                                </div>
                                                {showOTP === true ?
                                                    <ShowPatientOtp
                                                        doctorId={doctorId}
                                                        patientId={patientId}
                                                        loginData={loginData}
                                                        mobile={mobile} />
                                                    :
                                                    <>
                                                        <div className="mr-3" align='left'>
                                                            <MainButtonInput onClick={handleSubmit}>Go</MainButtonInput>
                                                        </div>
                                                    </>}
                                            </div>
                                            <div className="validation mb-2 " align='right'>
                                                {isError}
                                            </div>

                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}