import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import TreatmentApi from '../../../services/TreatmentApi';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Modal, Button } from "react-bootstrap";

export default function GetAllTreatments(props) {
    const { getServices } = props
    const { updateTreatmentStatus } = TreatmentApi();
    const [show, setShow] = useState(false)
    const [treatmentId, setTreatmentId] = useState('');

    const handleShow = (id) => {
        setTreatmentId(id)
        setShow(true)
    }

    const handleClose = () => setShow(false)

    const handleDone = (treatmentId) => {
        const status = {
            status: "Completed"
        }
        updateTreatmentStatus(treatmentId, status)
        .finally(() => {
            setShow(false);
        });
    }

    return (
        <>
            
                <>
                    {getServices && getServices.length > 0 ?
                        <div className='margin_top_15'>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align='center'><span className='patientModal'>Index</span></TableCell>
                                            <TableCell align='center'><span className='patientModal'>Treatment Name</span></TableCell>
                                            <TableCell align='center'><span className='patientModal'>Doctor Name</span></TableCell>
                                            <TableCell align='center'><span className='patientModal'>Fees</span></TableCell>
                                            <TableCell align='center'><span className='patientModal'>Plan Date</span></TableCell>
                                            <TableCell align='center'><span className='patientModal'>Status</span></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {getServices && getServices.map((item, i) => {
                                            return (
                                                <TableRow key={i}>
                                                    <TableCell align='center'>{i + 1}</TableCell>
                                                    <TableCell align='center'>{item.treatmentName}</TableCell>
                                                    <TableCell align='center'>{item['doctorList'][0].name}</TableCell>
                                                    <TableCell align='center'>{item.treatmentPrice}</TableCell>
                                                    <TableCell align='center'>{(moment(item.planDate).format('YYYY-MM-DD'))}</TableCell>
                                                    <TableCell align='center'>{item.status}</TableCell>
                                                    <TableCell align='center'>
                                                        {item.parentId === item.doctorId ?
                                                            <div className="mt-2">
                                                                {item.status === "Completed" ? null :
                                                                    <Link
                                                                        to="#"
                                                                        onClick={() => handleShow(item._id)}
                                                                        className=" editbutton">
                                                                        Done
                                                                    </Link>
                                                                }
                                                            </div>
                                                            : null}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                        : null
                    }
                </>
            
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Are you sure?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="alert modalColor">
                        You want to complete this Treatment.
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" className='appColor' onClick={() => handleDone(treatmentId)}>
                        Yes
                    </Button>
                    <Button variant="default" className="borderStyle" onClick={handleClose}>
                        No
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
