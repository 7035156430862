import React, { useEffect } from 'react';
import { Navigate, Route, Routes} from "react-router-dom";
import LoginDoctor from "./doctor/Profile/LoginDoctor";
import DoctorProfile from "./doctor/Profile/DoctorProfile";
import EditDoctorProfile from "./doctor/Profile/EditDoctorProfile";
import Dashboard from './doctor/Dashboard-card/Dashboard';
import PatientMedicalReport from "./doctor/Report/PatientMedicalReport";
import PatientsClinicHistory from "./doctor/Dashboard-card/PatientsClinicHistory";
import CreatePatientProfile from "./patient/createPatientProfile";
import GetLoginPatientProfile from "./patient/getLoginPatientProfile";
import ViewMedicalReport from './doctor/Report/ViewMedicalReport';
import Logout from "./doctor/Profile/LogoutForm";
import LoginHelper from "./doctor/Profile/LoginHelper";
import Helper from "./doctor/helper/Helper";
import EditHelper from './doctor/helper/EditHelper';
import Patient from "./patient/patient";
import { AppointmentBookingSection } from "./patient/appointmentBookingSection";
import PatientData from "./doctor/Dashboard-card/partial/patientData";
import MedicineList from "./doctor/Dashboard-card/MedicineList";
import { setDoctorId } from "./recoil/atom/setDoctorId";
import { useRecoilState } from "recoil";
import AddLabWork from './doctor/Dashboard-card/LabWork';
import PaymentList from './doctor/Dashboard-card/report/paymentlist';
import LabWorkList from './doctor/Dashboard-card/report/labworklist';
import Summary from './doctor/Report/partial/summary';
import MpinForm from './doctor/Profile/mpinform';
import ForgetPatientMpin from './patient/forgetPatientMpin';
import ForgotDrMpin from './doctor/Profile/Partial/ForgotDrMpin';
import AddHelper from './doctor/helper/partial/AddHelper';
import Reports from './doctor/Dashboard-card/report/reportlist';
import CreateDoctorMpin from './doctor/Profile/Partial/CreateDoctorMpin';
import PatientLoginForm from './patient/patientLoginForm';
import { PatientMpin } from './patient/PatientMpin';
import Inwards from './doctor/Dashboard-card/inventory/Inwards';
import Outwards from './doctor/Dashboard-card/inventory/Outwards';
import { ClinicList } from './patient/ClinicList';
import AddDoctor from './doctor/Profile/newdoctor/AddDoctors';
import { AddNewDoctor } from './doctor/Profile/newdoctor/AddNewDoctor';
import { AllClinicList } from './doctor/Profile/newdoctor/clinicList';
import CreateNewDoctorMpin from './doctor/Profile/newdoctor/createMpin';
import NewDoctorMpinForm from './doctor/Profile/newdoctor/mpinform';
import { UpdateNewDoctor } from './doctor/Profile/newdoctor/updateNewDoctor';
import AddPackages from './doctor/Dashboard-card/tourism/AddPackage';
import Packages from './doctor/Dashboard-card/tourism/packages';
import UpdatePackage from './doctor/Dashboard-card/tourism/UpdatePackage';
import ViewFullPackage from './doctor/Dashboard-card/tourism/ViewFullPackage';
import AssignDoctorTreatment from './doctor/Report/Treatment/AssignDoctorTreatment';

function MainContainer() {
  const [doctorId] = useRecoilState(setDoctorId);

  useEffect(() => {
    // Function to clear localStorage
    const clearLocalStorage = () => {
      localStorage.removeItem('setDoctorId'); // Replace 'yourRecoilDataKey' with your actual key
    };
    // Add event listener for beforeunload
    window.addEventListener('beforeunload', clearLocalStorage);
    // Remove event listener when component unmounts
    return () => {
      window.removeEventListener('beforeunload', clearLocalStorage);
    };
  }, [])

  return (
    <Routes>
      <Route exact path="/" element={<MpinForm />} />
      {/* Login route, will redirect to profile if already logged in */}
      <Route path="/" element={doctorId ? <Navigate to={`/profile/${doctorId}`} /> : <MpinForm />} />
      <Route exact path="/create" element={<CreateDoctorMpin />} />
      <Route exact path="/forgetmpin" element={<ForgotDrMpin />} />
      <Route path="/login" element={<LoginDoctor />} />
      {/* <Route path="/subscriptions/:doctorId" element={doctorId ? <Subscription /> : <Navigate to='/' />} /> */}
      <Route path="/dashboard/:doctorId" element={doctorId ? <Dashboard /> : <Navigate to='/' />} />

      {/* doctor profile */}
      <Route path="/profile/:doctorId">
        <Route index element={doctorId ? <DoctorProfile /> : <Navigate to='/' />} />
        <Route path="edit" element={doctorId ? <EditDoctorProfile /> : <Navigate to='/' />} />
      </Route>

      {/* Appointments */}
      <Route path="/appointments/:doctorId" >
        <Route index element={doctorId ? <Patient /> : <Navigate to='/' />} />
        <Route path="bookappointment" >
          <Route index element={doctorId ? <PatientMpin /> : <Navigate to='/' />} />
          <Route path="createpatientmpin" element={doctorId ? <PatientLoginForm /> : <Navigate to='/' />} />
          <Route path="forgetpatientmpin" element={doctorId ? <ForgetPatientMpin /> : <Navigate to='/' />} />
        </Route>
        <Route path="consultation/:reportId" >
          <Route index element={doctorId ? <PatientMedicalReport /> : <Navigate to='/' />} />
          <Route path="summary" element={doctorId ? <Summary /> : <Navigate to='/' />} />
        </Route>
        <Route path="patientprofile/:patientId">
          <Route index element={doctorId ? < GetLoginPatientProfile /> : <Navigate to='/' />} />
          <Route path="booking">
            <Route index element={doctorId ? <ClinicList /> : <Navigate to='/' />} />
            <Route path="bookslot/:clinicId" element={doctorId ? <AppointmentBookingSection /> : <Navigate to='/' />} />
          </Route>
        </Route>
        <Route path="createprofile/:patientId" element={doctorId ? < CreatePatientProfile /> : <Navigate to='/' />} />
        <Route path="patientdata/:patientId" element={doctorId ? <PatientData /> : <Navigate to='/' />} />
      </Route>

      {/* Appointments history */}
      <Route path="/history/:doctorId" >
        <Route index element={doctorId ? <PatientsClinicHistory /> : <Navigate to='/' />} />
        <Route path="report/:reportId" element={doctorId ? <ViewMedicalReport /> : <Navigate to='/' />} />
      </Route>

      {/* Assistant */}
      <Route path="/helper/:doctorId">
        <Route index element={doctorId ? <Helper /> : <Navigate to='/' />} />
        <Route path="update/:helperId" element={doctorId ? <EditHelper /> : <Navigate to='/' />} />
        <Route path="addhelper" element={<AddHelper />} />
      </Route>

      {/* Add Doctors */}
      <Route path="/newdoctor/:doctorId">
        <Route index element={doctorId ? <AddDoctor /> : <Navigate to='/' />} />
        <Route path="addnewdoctor" element={doctorId ? <AddNewDoctor /> : <Navigate to='/' />} />
        <Route path="updatenewdoctor/:id" element={doctorId ? <UpdateNewDoctor /> : <Navigate to='/' />} />
        <Route path="clinics/:id" element={<AllClinicList />} />
        <Route path="loginwithmpin" element={<NewDoctorMpinForm />} />
        <Route path="creatempin" element={<CreateNewDoctorMpin />} />
      </Route>
      <Route path='/doctortreatment/:doctorId'>
        <Route index element={doctorId ? <AssignDoctorTreatment /> : <Navigate to='/' />} />
      </Route>
      {/* medicine List */}
      <Route path="/medicinelist/:doctorId" element={doctorId ? <MedicineList /> : <Navigate to='/' />} />
      <Route path="/logout" element={< Logout />} />
      <Route path="/helperlogin" element={<LoginHelper />} />

      {/* lab Work */}
      <Route path="/addlab/:doctorId" element={doctorId ? <AddLabWork /> : <Navigate to='/' />} />
      <Route path="/reports/:doctorId/">
        <Route path="paymentlist" element={<PaymentList />} />
        <Route path="labworklist" element={<LabWorkList />} />
        <Route path="reportlist" element={<Reports />} />
      </Route>

      {/* inventory */}
      <Route path='/inventory/:doctorId/'>
        <Route path="inwards" element={<Inwards />} />
        <Route path="outwards" element={<Outwards />} />
      </Route>

      {/* tourism pakages */}
      <Route path="/tourism/:doctorId">
        <Route index element={doctorId ? <Packages /> : <Navigate to='/' />} />
        <Route path="update/:packageId" element={doctorId ? <UpdatePackage /> : <Navigate to='/' />} />
        <Route path="package/:packageId" element={doctorId ? <ViewFullPackage /> : <Navigate to='/' />} />
        <Route path="addpackage" element={<AddPackages />} />
      </Route>
    </Routes>
  )
}
export default MainContainer;