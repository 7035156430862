import axios from "axios"
import { API } from "../config"

export default function TreatmentApi() {

    const CreateTreatments = async (bodyData) => {
        try {
            const result = await axios.post(`${API}/treatments`, bodyData);
            return result.data;
        }
        catch (err) {
            return err
        }
    }
    const fetchTreatmentbyAppointmentId = async (appointmentId) => {
        try {
            const result = await axios.get(`${API}/gettreatments/${appointmentId}`);
            return result.data;
        }
        catch (err) {
            return err
        }
    }

    const fetchTreatmentbyOwnId = async (doctorId) => {
        try {
            const result = await axios.get(`${API}/owntreatments/${doctorId}`);
            return result.data;
        }
        catch (err) {
            return err
        }
    }

    const updateTreatmentStatus = async (treatmentId, status) => {
        try {
            const result = await axios.post(`${API}/statusupdate/${treatmentId}`, status);
            return result.data;
        }
        catch (err) {
            return err
        }
    }

    const updatefollowupDate = async (treatmentId, date) => {
        try {
            const result = await axios.post(`${API}/updatefollowupdate/${treatmentId}`, date);
            return result.data;
        }
        catch (err) {
            return err
        }
    }

     const createTreatmentPDF = async (appointmentId) => {
            try {
                const result = await axios.post(`${API}/createPDF/${appointmentId}`)
                return result.data;
            }
            catch (err) {
                return err
            }
        };
    const downloadTreatemntPDF = async (appointmentId) => {
        try {
            const result = await axios.get(`${API}/getPDF/${appointmentId}`);
            return result.data;
        }
        catch (err) {
            return err
        }
    }
    return {
        CreateTreatments,
        fetchTreatmentbyOwnId,
        fetchTreatmentbyAppointmentId,
        updateTreatmentStatus,
        updatefollowupDate,
        createTreatmentPDF,
        downloadTreatemntPDF
    }
}