import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FaRupeeSign } from "react-icons/fa";
import { setNewPatientId } from "../recoil/atom/setNewPatientId";
import { setFetchPatientData } from "../recoil/atom/setFetchPatientData";
import { useRecoilState } from "recoil";
import PatientApi from "../services/PatientApi";
import { Button, Modal } from "react-bootstrap";
import { setDependentId } from "../recoil/atom/setDependentId";
import moment from "moment";
import AuthApi from "../services/AuthApi";
import Toaster from "../doctor/Toaster";
import { setDoctorId } from "../recoil/atom/setDoctorId";

const ShowInClinicAppointSlots = (props) => {
    const { sessionSlot, selectedDate, session, slotDate, showSlot } = props;
    const [patientId, setPatientsId] = useRecoilState(setNewPatientId)
    const [dependentId, setDependentsId] = useRecoilState(setDependentId)
    const [fetchPatientData, setFetchPatientdata] = useRecoilState(setFetchPatientData)
    const [bookingSlots, setBookingSlots] = useState([]);
    const [doctorName, setDoctorName] = useState([]);
    const [show, setShow] = useState(false);
    const [bookSlot, setbookSlot] = useState([]);
    const { paymentInfo, getbookedSlots } = PatientApi();
    const { getDrInfo } = AuthApi();
    const navigate = useNavigate();
    const [DoctorId] = useRecoilState(setDoctorId)

    useEffect(() => {
        availableSlots()
        getDoctorData()
    }, [props])

    function getDoctorData() {
        const doctorId = session.doctorId
        getDrInfo({ doctorId })
            .then((response) => {
                const name = response.result[0].name
                let fullName = name.split(' '),
                    firstName = fullName[0],
                    lastName = fullName[fullName.length - 1];
                setDoctorName("Dr. " + lastName)
            })
    }

    const handleShow = (item) => {
        setShow(true)
        setbookSlot(item)
    }
    const handleClose = () => setShow(false)

    const handleSelectedSlot = (item) => {
        const startDate = (selectedDate + " " + item.time)
        const transactionData = {
            "DoctorId": session.doctorId,
            "ClinicId": session.clinicId,
            "slotId": item._id,
            "patientId": patientId,
            "dependentId": dependentId !== " " ? dependentId : null,
            "transactionId": '123',
            "currency": 'INR',
            "fees": session.fees,
            "date": slotDate,
            "day": session.day,
            "slotTime": item.time,
            "daySlotId": session._id,
            "selectedDate": selectedDate,
            "timeSlot": session.timeSlot,
            "startDate": startDate,
            "status": "Ongoing",
            "payment": "hold",
            "parent" :DoctorId,
            "patientmobile": fetchPatientData.mobile,
            "doctorname": doctorName
        }
        paymentInfo(transactionData)
            .then((res) => {
                navigate(`/appointments/${DoctorId}`);
                setDependentsId(' ')
                setPatientsId(' ')
            })
            .catch((error) => {
                console.error('Payment error:', error);
            });
        handleClose();
    }


    const checkSlotAvailability = (slot) => {
        const currentDate = moment(new Date()).format("YYYY-MM-DD HH:mm")
        const slotDateTime = moment(new Date(selectedDate)).format("YYYY-MM-DD") + " " + slot.time
        const returnData = currentDate > slotDateTime || bookingSlots.some(func => (func.slotId === slot._id && func.status !== "Cancelled" && func.startDate === slotDateTime))
        return returnData
    }

    const categorizeSlots = () => {
        let morningSlots = [];
        let afternoonSlots = [];
        let eveningSlots = [];
        sessionSlot.forEach((slot) => {
            const [hour] = slot.time.split(":").map(Number);
            if (hour < 12) {
                morningSlots.push(slot);
            } else if (hour < 17) {
                afternoonSlots.push(slot);
            }
            else {
                eveningSlots.push(slot);
            }
        });
        return { morningSlots, afternoonSlots, eveningSlots };
    };

    let { morningSlots, afternoonSlots, eveningSlots } = categorizeSlots();

    const availableSlots = () => {
        getbookedSlots(session.doctorId, session.clinicId)
            .then((result) => {
                if (result) {
                    const data = result.filter((item) => {
                        if (item.date === slotDate)
                            return item
                    })
                    setBookingSlots(data)
                } else {
                    return null
                }
            })
    }


   
    return (
        <>
            <div style={{ flexWrap: 'wrap' }}>
                <span className="font_weight " style={{ color: "black" }}>
                    {slotDate}&nbsp;
                    Fees - <FaRupeeSign /> {session.fees} /-
                </span>

                {morningSlots.length === 0 ? (
                    null
                ) : (
                    <>
                        <h6 align='left' className="font_weight  mt-2">MORNING </h6>
                        <section className=" radiobutton">
                            {
                                morningSlots.map((item, index) => (
                                    <div key={index}>
                                        {(checkSlotAvailability(item))
                                            ?
                                            <div>
                                                <div
                                                    className="disabled-div"
                                                    type="radio"
                                                >
                                                    <label>{item.time}</label>
                                                </div>
                                            </div>
                                            :
                                            <div>
                                                <Link
                                                    to='#'
                                                    onClick={() => handleShow(item)}
                                                    className="btn_1"
                                                    type="radio"
                                                >
                                                    <label>{item.time}</label>
                                                </Link>
                                            </div>
                                        }
                                    </div>
                                ))
                            }
                        </section>
                    </>
                )}

                {afternoonSlots.length === 0 ? (
                    null
                ) : (
                    <>
                        <h6 align='left' className="font_weight  mt-2">AFTERNOON </h6>
                        <section className=" radiobutton">
                            {afternoonSlots.map((item, index) => (
                                <div key={index}>
                                    {(checkSlotAvailability(item))
                                        ?
                                        <div>
                                            <div
                                                className="disabled-div"
                                                type="radio"
                                            >
                                                <label>{item.time}</label>
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            <Link
                                                to='#'
                                                onClick={() => handleShow(item)}
                                                className="btn_1"
                                                type="radio"
                                            >
                                                <label>{item.time}</label>
                                            </Link>
                                        </div>
                                    }
                                </div>
                            ))}
                        </section>
                    </>
                )}
                {eveningSlots.length === 0 ? (
                    null
                ) : (
                    <>
                        <h6 align='left' className="font_weight mt-2">EVENING </h6>
                        <section className=" radiobutton">
                            {eveningSlots.map((item, index) => (
                                <div key={index}>
                                    {(checkSlotAvailability(item))
                                        ?
                                        <div>
                                            <div
                                                className="disabled-div"
                                                type="radio"
                                            >
                                                <label>{item.time}</label>
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            <Link
                                                to='#'
                                                onClick={() => handleShow(item)}
                                                className="btn_1"
                                                type="radio"
                                            >
                                                <label>{item.time}</label>
                                            </Link>
                                        </div>
                                    }
                                </div>
                            ))}
                        </section>
                    </>
                )}
                
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Are you sure?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="alert modalColor ">You want to book this appointment. </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="default" className='appColor' onClick={() => handleSelectedSlot(bookSlot)}>
                            Yes
                        </Button>
                        <Button variant="default" className='borderStyle' onClick={handleClose}>
                            No
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Toaster />
            </div >
        </>
    )
}
export { ShowInClinicAppointSlots }