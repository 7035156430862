import React, { useEffect, useState, useRef } from 'react';
import { Link } from "react-router-dom";
import Table from '@mui/material/Table';
import { Modal } from "react-bootstrap";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ModeEditOutlineSharpIcon from '@mui/icons-material/ModeEditOutlineSharp';
import Loader from '../Loader';
import CloseIcon from '@mui/icons-material/Close';
import { Drawer, IconButton, useMediaQuery } from '@mui/material';
import TouristAppointmentsApi from '../../../services/TouristAppointmentsApi';
import { UpdatePackageType } from './UpdatePackageTypes';

export default function PackagePlansList(props) {
    const { doctorId } = props
    const [packageId, setPackageId] = useState(false);
    const [activeModal, setActiveModal] = useState();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [showData, setShowData] = useState(false);
    const [packages, setPackages] = useState(null);
    const { GetAllPackageTypes } = TouristAppointmentsApi()
    const isMobile = useMediaQuery('(max-width:768px)')

    useEffect(() => {
        getAllPackages()
    }, []);

    const getAllPackages = () => {
        GetAllPackageTypes()
            .then((res) => {
                setPackages(res)
                setIsLoading(false)
            })
    }

    const openDrawerWithModal = (modalType, id) => {
        setPackageId(id)
        setActiveModal(modalType);
        setDrawerOpen(true);
    };

    const handleDataShow = (id) => {
        setPackageId(id)
        setShowData(true)
    }

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleDataClose = () => {
        setShowData(false)
        setDrawerOpen(false)
    }

    const renderModalContent = () => {
        switch (activeModal) {
            case 'packageedit':
                return <UpdatePackageType
                    doctorId={doctorId}
                    packageId={packageId}
                    onSubmit={handleDataClose}
                />
            default:
                return null;
        }
    };

    return (
        <>
            {isLoading === true ?
                <div className='loader-container'>
                    <Loader />
                </div>
                :
                <>
                    {packages ?
                        <div className='row'>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="font_weight" align="left">Package Plan Name</TableCell>
                                            <TableCell className="font_weight" align="left">Features</TableCell>
                                            <TableCell className="font_weight" align="left">Inclusions</TableCell>
                                            <TableCell className="font_weight" align="left">Exclusions</TableCell>
                                            <TableCell className="font_weight" align="left">Price</TableCell>
                                            <TableCell className="font_weight" align="left">View</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {packages && packages.map((item, index) => {
                                            return (
                                                <TableRow key={item._id}>
                                                    <TableCell align="left">
                                                        {item.packageName}
                                                    </TableCell>


                                                    <TableCell align="left">
                                                    {item.features && item.features.map((item, i) => {
                                                        return (
                                                            <span key={i}>
                                                                {item}<br />
                                                            </span>
                                                        )
                                                    })}
                                                    </TableCell>

                                                    <TableCell align="left">
                                                        {item.inclusions}
                                                    </TableCell>

                                                    <TableCell align="left">
                                                        {item.exclusions}
                                                    </TableCell>

                                                    <TableCell align="left">
                                                        {item.packagePrice}
                                                    </TableCell>

                                                    <TableCell align="left">
                                                        <Link onClick={() => isMobile ? openDrawerWithModal('packageedit', item._id) : handleDataShow(item._id)}>
                                                            <ModeEditOutlineSharpIcon style={{ fontSize: 20 }} />
                                                        </Link>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                        :
                        <div className="clinicHistory font_weight" >Appointments are not Available</div>
                    }

                    {isMobile ?
                        <Drawer anchor="bottom" open={drawerOpen} onClose={handleDrawerToggle}>
                            <div className='p-4'>
                                <div align='right'>
                                    <IconButton onClick={handleDrawerToggle}>
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                                <div className='p-2'>
                                    {renderModalContent()}
                                </div>
                            </div>
                        </Drawer>
                        :
                        <Modal show={showData} onHide={handleDataClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Update PackagePlansList</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <UpdatePackageType
                                    doctorId={doctorId}
                                    packageId={packageId}
                                    onSubmit={handleDataClose}
                                />
                            </Modal.Body>
                        </Modal>
                    }
                </>
            }
        </>
    )
}