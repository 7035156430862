import React, { useEffect, useState } from "react";
import TreatmentApi from "../../../services/TreatmentApi";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import moment from 'moment';
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';

export default function TreatmentDoctor(props) {
    const { doctorId } = props
    const { fetchTreatmentbyOwnId, updateTreatmentStatus, updatefollowupDate } = TreatmentApi()
    const [getServices, setGetServices] = useState(null);
    const [showCalender, setShowCalender] = useState(false);
    const [showComplete, setShowComplete] = useState(false);
    const [treatmentId, setTreatmentId] = useState('')
    const [value, setValue] = useState(dayjs());

    useEffect(() => {
        fetchTreatment()
    }, []);

    const fetchTreatment = () => {
        fetchTreatmentbyOwnId(doctorId)
            .then((res) => {
                setGetServices(res.doc)
            })
    }

    const handleCalenderShow = (id) => {
        setTreatmentId(id)
        setShowCalender(true)
    }

    const handleCalenderClose = () => setShowCalender(false)

    const handleCalender = (treatmentId) => {
        const date = {
            followup: value,
            type: "followUp"
        }
        updatefollowupDate(treatmentId, date)
        setShowCalender(false)
    }

    const handleCompleteShow = (id) => {
        setTreatmentId(id)
        setShowComplete(true)
    }

    const handleCompleteClose = () => setShowComplete(false)

    const handleDone = (treatmentId) => {
        const status = {
            status: "Completed"
        }
        updateTreatmentStatus(treatmentId, status)
        setShowComplete(false)
    }
    return (
        <>
            {getServices ?
                <div className='margin_top_15'>
                    {/* <h6 align="left" className='patientModal ml-2'>Medicine List</h6> */}
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align='center'><span className='patientModal'>Index</span></TableCell>
                                    <TableCell align='center'><span className='patientModal'>Treatment Name</span></TableCell>
                                    <TableCell align='center'><span className='patientModal'>Fees</span></TableCell>
                                    <TableCell align='center'><span className='patientModal'>Plan Date</span></TableCell>
                                    <TableCell align='center'><span className='patientModal'>Status</span></TableCell>
                                    <TableCell align='center'><span className='patientModal'>Action</span></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {getServices && getServices.map((item, i) => {
                                    return (
                                        <TableRow key={i}>
                                            <TableCell align='center'>{i + 1}</TableCell>
                                            <TableCell align='center'>{item.treatmentName}</TableCell>
                                            <TableCell align='center'>{item.treatmentPrice}</TableCell>
                                            <TableCell align='center'>{(moment(item.planDate).format('YYYY-MM-DD'))}</TableCell>
                                            <TableCell align='center'>{item.status}</TableCell>
                                            <TableCell align='center'>
                                                {item.status === "Completed" ? 'Completed' :
                                                    <>
                                                        <Link
                                                            to="#"
                                                            onClick={() => handleCalenderShow(item._id)}
                                                            className="mr-2 font_size_20">
                                                            <i className="icon-calendar-1"
                                                                title="follow up">
                                                            </i>
                                                        </Link>
                                                        <Link
                                                            to="#"
                                                            onClick={() => handleCompleteShow(item._id)}
                                                            className="mr-2 font_size_20">
                                                            Done
                                                        </Link>
                                                    
                                                    </>}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                : null}
            <Modal show={showCalender} onHide={handleCalenderClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Select follow up Date</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DateCalendar', 'DateCalendar']}>
                            <DemoItem>
                                <DateCalendar
                                    value={value}
                                    onChange={(newValue) => {
                                        setValue(newValue);
                                    }}
                                />
                            </DemoItem>
                        </DemoContainer>
                    </LocalizationProvider>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" className='appColor' onClick={() => handleCalender(treatmentId)}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showComplete} onHide={handleCompleteClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Are you sure?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="alert modalColor">
                        You want to complete this Treatment.
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" className='appColor' onClick={() => handleDone(treatmentId)}>
                        Yes
                    </Button>
                    <Button variant="default" className="borderStyle" onClick={handleCompleteClose}>
                        No
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}