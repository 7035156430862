import React, {  useState } from 'react';
import ReportApi from '../../../services/ReportApi';
import "react-toastify/dist/ReactToastify.css";
import Toaster from '../../Toaster';
import { MainButtonInput } from '../../../mainComponent/mainButtonInput';
import { setPatientGeneralInfo } from '../../../recoil/atom/setPatientGeneralInfo';
import { useRecoilState } from 'recoil';
import { validateForm } from '../../Dashboard-card/validateForm';

export default function PatientGeneralInfo(props) {
    const { reportId } = props;
    const { insertPatientVitalSignsData } = ReportApi()
    const [generalInfo, setGeneralInfo] = useRecoilState(setPatientGeneralInfo)
    const [bmi, setBmi] = useState(0);
    const [errors, setErrors] = useState({});
    const [changeData, setChangeData] = useState({});

    const handleChange = (event) => {
        const { name, value } = event.target;
        setChangeData({ ...changeData, [name]: value })
    }

    const calculateBmi = () => {
        const heightInMeters = changeData.height / 100;
        const calculatedBmi = changeData.weight / (heightInMeters * heightInMeters);
        setBmi(calculatedBmi.toFixed(2));
    };

    const saveData = (e) => {
        e.preventDefault();
        const validation = validateForm({
            changeData
        });

        if (!validation.formIsValid) {
            setErrors(validation.errors);
            return;
        }
        
        const bodyData = {
            "age": changeData.age,
            "weight": changeData.weight,
            "height": changeData.height,
            "BMI": bmi,
            "temp": changeData.temp,
            "bp": changeData.bp,
            "pulse": changeData.pulse,
            "problem": changeData.problem,
        }
        insertPatientVitalSignsData({ reportId }, bodyData)
            .then((res) => {
                if (reportId === res._id) {
                    setGeneralInfo(res)
                } else {
                    return res
                }
                // toast.success("Saved Successfully!")
            })
        props.handleClose()
    }

    return (
        <>
            <div className="row">
                <div className="col-md-6">
                    <label className='left' >Age *</label>
                    <input
                        type="text"
                        className="form-control"
                        value={changeData.age}
                        name="age"
                        placeholder="Age"
                        onChange={handleChange}
                    />
                    {errors.age && <div className="validation">{errors.age}</div>}
                </div>
                <div className="col-md-6 ">
                    <label className='left'>Blood Pressure</label>
                    <input
                        type="text"
                        className="form-control"
                        value={changeData.bp}
                        name="bp"
                        placeholder="Blood Pressure"
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-md-6">
                    <label className='left'>Temperature</label>
                    <input
                        type="text"
                        className="form-control"
                        value={changeData.temp}
                        name="temp"
                        placeholder="Temperature"
                        onChange={handleChange}
                    />
                </div>
                <div className="col-md-6">
                    <label className='left'>Pulse</label>
                    <input
                        type="text"
                        className="form-control"
                        value={changeData.pulse}
                        name="pulse"
                        placeholder="Pulse"
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className="row  mt-2">
                <div className="col-md-6">
                    <label className='left'>Weight (kg)*</label>
                    <input
                        type="text"
                        value={changeData.weight}
                        className="form-control"
                        name="weight"
                        placeholder="Weight"
                        onChange={handleChange}
                    />
                    {errors.weight && <div className="validation">{errors.weight}</div>}
                </div>
                <div className="col-md-6">
                    <label className='left' >Height (cm)*</label>
                    <input
                        type="text"
                        className="form-control"
                        value={changeData.height}
                        name="height"
                        placeholder="Height"
                        onChange={handleChange}
                    />
                    {errors.height && <div className="validation">{errors.height}</div>}
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-md-6">
                    {/* <label className='left mt-2'>BMI</label> */}
                    <MainButtonInput onClick={calculateBmi}>Compute BMI</MainButtonInput>
                </div>
                <div className="col-md-6">
                    <input
                        type="text"
                        value={bmi}
                        className="form-control"
                        name="BMI"
                        placeholder="BMI"
                    />
                </div>
            </div>
            <div className="col-md-12">
                <label className='left'>Problem</label>
                <textarea
                    type="text"
                    value={changeData.problem}
                    onChange={handleChange}
                    className="form-control"
                    name="problem"
                    placeholder="Problem"
                />
            </div>
            <div className="row float-right">
                <Toaster />
            </div>
            <div className="float-right mt-3 mr-3">
                <MainButtonInput onClick={saveData}> Save</MainButtonInput>
            </div>
        </ >
    )

}
