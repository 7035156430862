import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import "react-toastify/dist/ReactToastify.css";
import Toaster from '../../Toaster';
import { toast } from 'react-toastify';
import moment from 'moment';
import dayjs from 'dayjs';
import { useRecoilState } from 'recoil';
import { SecondaryButtonInput } from '../../../mainComponent/secondaryButtonInput';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { setFollowupSlots } from '../../../recoil/atom/setFollowupSlots';
import { Button, Modal } from 'react-bootstrap';
import { setDependentId } from '../../../recoil/atom/setDependentId';
import ReportApi from '../../../services/ReportApi';
import PatientApi from '../../../services/PatientApi';
import AppointmentsApi from '../../../services/AppointmentsApi';

export default function NewFollowup(props) {
    const { reportId, doctorId } = props
    const [clinicId, setClinicId] = useState([]);
    const [events, setEvents] = useState([]);
    const [selectedDateEvents, setSelectedDateEvents] = useState(null);
    const [bookingSlots, setBookingSlots] = useState([]);
    const [paymentStatus, setPaymentStatus] = useState([])
    const [followupSlots] = useRecoilState(setFollowupSlots);
    const [dependentId] = useRecoilState(setDependentId)
    const [appointmentId, setAppointmentId] = useState([])
    const [appointmentData, setAppointmentData] = useState([])
    const [show, setShow] = useState(false);
    const [data, setData] = useState([]);
    const [bookSlot, setbookSlot] = useState([]);
    const [slotDate, setSlotDate] = useState([]);
    const [selectedDate, setSelectedDate] = useState([]);
    const [value, setValue] = useState(dayjs());
    const { 
        fetchSessionSlotsData,
        getbookedSlots,
        followupBooking 
    } = PatientApi();
    const { getMedicineReport, insertNewFollowUpDate } = ReportApi()
    const { UpdateStatusBookingdata, getPaymentData, getAppointment, createPDF } = AppointmentsApi()
    const navigate = useNavigate();

    useEffect(() => {
        getSlots()
        getMedicineReportData()
    }, [])

    const getMedicineReportData = () => {
        getMedicineReport({ reportId })
            .then((res) => {
                if (res) {
                    const { patientAppointmentId } = res[0]
                    setAppointmentId(patientAppointmentId)
                    getPaymentData(patientAppointmentId)
                        .then(data => {
                            setPaymentStatus(data[0])
                        })
                    getAppointment(patientAppointmentId)
                        .then(data => {
                            setAppointmentData(data)
                        })
                }
            })
    }
    const handleShow = () => {
        createPDF(reportId)
        navigate(`summary`)
    }

    const handleModalShow = (item) => {
        setShow(true)
        setbookSlot(item)
    }
    const handleClose = () => setShow(false)

    const getSlots = () => {
        const clinicId = followupSlots.clinicId;
        const doctorId = followupSlots.doctorId;
        setClinicId(followupSlots.clinicId)
        fetchSessionSlotsData({ doctorId, clinicId })
            .then((res) => {
                setEvents(res);
                fetchEventsForDate(value);
            })
            .catch((error) => {
                console.error('Error fetching slots:', error);
                toast.error("Error fetching slots");
            });
    };

    const fetchEventsForDate = (selectedDate) => {
        let days = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"]
        const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const selectedDay = dayjs(selectedDate).format('ddd').toLowerCase();
        const matchingEvents = events.find(event => event.day === selectedDay);
        const day = days[selectedDate.$d.getDay()];
        const month = monthNames[selectedDate.$d.getMonth()];
        const date = selectedDate.$d.getDate();
        const formattedDate = `${day} ${date} ${month}`;
        setSlotDate(formattedDate)
        setSelectedDate(moment(new Date(selectedDate)).format("YYYY-MM-DD"))
        if (matchingEvents) {
            setData(matchingEvents)
            setSelectedDateEvents(matchingEvents.showSelectedSlots);
        } else {
            setSelectedDateEvents([]);
        }
        availableSlots(doctorId, followupSlots.clinicId, formattedDate)
    };

    const availableSlots = (doctorId, clinicId, formattedDate) => {
        getbookedSlots(doctorId, clinicId)
            .then((result) => {
                if (result) {
                    const data = result.filter((item) => item.date === formattedDate);
                    setBookingSlots(data)
                } else {
                    setBookingSlots('')
                }
            })
    }

    const checkSlotAvailability = (slot) => {
        const currentDate = moment().format("YYYY-MM-DD HH:mm");
        const slotDateTime = moment(selectedDate + " " + slot.time).format("YYYY-MM-DD HH:mm");
        const isSlotBooked = currentDate > slotDateTime || bookingSlots.some((func) =>
            func.slotId === slot._id && func.status !== "Cancelled" && func.startDate === slotDateTime)
        return isSlotBooked;
    };

    const handleSelectedSlot = (item) => {
        const startDate = (selectedDate + " " + item.time)
        const transactionData = {
            "DoctorId": doctorId,
            "ClinicId": clinicId,
            "slotId": item._id,
            "patientId": followupSlots.patientId,
            "dependentId": dependentId !== " " ? dependentId : null,
            "transactionId": '123',
            "currency": 'INR',
            "fees": data.fees,
            "date": slotDate,
            "day": data.day,
            "slotTime": item.time,
            "daySlotId": data._id,
            "selectedDate": selectedDate,
            "followupDate": selectedDate,
            "timeSlot": data.timeSlot,
            "startDate": startDate,
            // "status": "Inprogress",
            "transactionType": "Followup",
            "patientmobile": appointmentData.patientmobile,
            "doctorname": appointmentData.doctorname
        }
        followupBooking(appointmentId, transactionData)
            .then((res) => {
                const bodyData = {
                    "status": "Inprogress",
                    "medicalReportId": reportId,
                    "payment": paymentStatus.payment,
                    "paymentId": paymentStatus.paymentId
                }
                UpdateStatusBookingdata({ appointmentId }, bodyData)
                const data = {
                    new_follow_up: selectedDate
                }
                insertNewFollowUpDate({ reportId }, data)
                createPDF(reportId)
                navigate(`summary`);
                // setDependentsId(' ')
                // setPatientsId(' ')
            })
            .catch((error) => {
                console.error('Payment error:', error);
            });
        // requestToken(followupSlots.patientId, doctorId, item.time)
        handleClose();
    }

    return (
        <div>
            <div align='left' className="form-group">
                Calendar for Next Follow-Up
            </div>

            <div className='row'>
                <div className="col-md-6 calendar-container">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DateCalendar', 'DateCalendar']}>
                            <DemoItem>
                                <DateCalendar
                                    value={value}
                                    onChange={(newValue) => {
                                        setValue(newValue);
                                        fetchEventsForDate(newValue);
                                    }}
                                />
                            </DemoItem>
                        </DemoContainer>
                    </LocalizationProvider>
                </div>
                <div className="col-md-6 calendar-container">
                    <div style={{ flexWrap: 'wrap' }}>
                    </div>
                    <span className="font_weight" style={{ color: "black" }}>
                        {/* {slotDate}&nbsp;
                        Fees - <FaRupeeSign />
                        {data.fees} /- */}
                    </span>
                    <section className=" radiobutton">
                        {selectedDateEvents && selectedDateEvents.map((item, index) => (
                            <div key={index}>
                                {checkSlotAvailability(item) ?
                                    (
                                        <div
                                            className="disabled-div"
                                            type="radio"
                                        >
                                            <label>{item.time}</label>
                                        </div>
                                    )
                                    :
                                    (<div>
                                        <Link
                                            to='#'
                                            onClick={() => handleModalShow(item)}
                                            className="btn_1"
                                            type="radio"
                                        >
                                            <label>{item.time}</label>
                                        </Link>
                                    </div>)
                                }
                            </div>
                        ))}
                    </section>
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Are you sure?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="alert modalColor" >You want to book this appointment. </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="default" className='appColor' onClick={() => handleSelectedSlot(bookSlot)}>
                                Yes
                            </Button>
                            <Button variant="default" className='borderStyle' onClick={handleClose}>
                                No
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Toaster />
                </div>
            </div>

            <div className="row float-right">
                {/* <div className="text-left mt-2">
                    <MainButtonInput onClick={addNode}> Save</MainButtonInput>
                </div> */}
                <div className="text-left ml-2 mt-2">
                    <SecondaryButtonInput onClick={handleShow}>Summary</SecondaryButtonInput>
                </div>
            </div>
            <div className="row float-right">
                <Toaster />
            </div>
        </div>
    )
}