import React, { useEffect, useState } from 'react';
import ReportApi from '../../../services/ReportApi';
import "react-toastify/dist/ReactToastify.css";
import { MainButtonInput } from '../../../mainComponent/mainButtonInput';
import { SecondaryButtonInput } from '../../../mainComponent/secondaryButtonInput';
import { Modal } from 'react-bootstrap';
import PatientGeneralInfo from './PatientGeneralInfo';
import FetchPatientGeneralInfo from './FetchPatientGeneralInfo';
import EditPatientGeneralInfo from './EditPatientGeneralInfo';
import { Drawer, IconButton, Typography, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function PatientPersonalInfo(props) {
    const { reportId, onChange } = props;
    const { getMedicineReport } = ReportApi()
    const [generalInfo, setGeneralInfo] = useState(null)
    const [show, setShow] = useState(false);
    const [editShow, setEditShow] = useState(false);
    const [activeModal, setActiveModal] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const isMobile = useMediaQuery('(max-width:768px)')

    useEffect(() => {
        generalData()
    }, []);

    const generalData = () => {
        getMedicineReport({ reportId })
            .then((res) => {
                setGeneralInfo(res[0])
            })
    }

    const renderModalContent = () => {
        switch (activeModal) {
            case 'generalinfo':
                return <PatientGeneralInfo
                    generalData={generalData}
                    handleClose={handleClose}
                    reportId={reportId} />;
            case 'editgeneralinfo':
                return <EditPatientGeneralInfo
                    generalData={generalData}
                    handleClose={handleEditClose}
                    reportId={reportId} />;
            default:
                return null;
        }
    };

    const getDrawerTitle = () => {
        switch (activeModal) {
            case 'generalinfo':
                return 'Add Patient Information';
            case 'editgeneralinfo':
                return 'Update Patient Information';
            default:
                return '';
        }
    };


    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const openDrawerWithModal = (modalType) => {
        setActiveModal(modalType);
        setDrawerOpen(true);
    };

    const handleClose = () => {
        setShow(false)
        setDrawerOpen(false);
    }
    const handleEditClose = () => {
        setEditShow(false)
        setDrawerOpen(false);
    }

    return (
        <div>
            <div className="row justifyContent" >
                {generalInfo && generalInfo.age ?
                    <div className="text-left mr-3">
                        <MainButtonInput defaultValue onClick={() => isMobile ? openDrawerWithModal('editgeneralinfo') : setEditShow(true)}>Edit </MainButtonInput>
                    </div> :
                    <div className="text-left mr-3">
                        <MainButtonInput onClick={() => isMobile ? openDrawerWithModal('generalinfo') : setShow(true)}>Add Information</MainButtonInput>
                    </div>
                }
                <div className="text-left ">
                    <SecondaryButtonInput onClick={onChange}>Next</SecondaryButtonInput>
                </div>
            </div>

            {/* {generalInfo && generalInfo.age ? */}
            <FetchPatientGeneralInfo reportId={reportId} />
            {/* : null} */}

            {isMobile ? (
                <Drawer anchor="bottom" open={drawerOpen} onClose={handleDrawerToggle}>
                    <div className='p-4'>
                        <div className='drawerTitle underline' >
                            <Typography variant="h6">
                                {getDrawerTitle()}
                            </Typography>
                            <IconButton onClick={handleDrawerToggle} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <div className='p-2'>
                            {renderModalContent()}
                        </div>
                    </div>
                </Drawer>
            )
                : (
                    <>
                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title >Add Patient Information</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <PatientGeneralInfo
                                    generalData={generalData}
                                    handleClose={handleClose}
                                    reportId={reportId}
                                />
                            </Modal.Body>
                        </Modal>

                        <Modal show={editShow} onHide={handleEditClose}>
                            <Modal.Header closeButton>
                                <Modal.Title >Edit Patient Information</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <EditPatientGeneralInfo
                                    generalData={generalData}
                                    handleClose={handleEditClose}
                                    reportId={reportId}
                                />
                            </Modal.Body>
                        </Modal>
                    </>
                )}
        </div>
    )

}
