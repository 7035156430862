import React, { useEffect, useState } from 'react';
import { Autocomplete, Box, TextField } from '@mui/material';
import ServicesApi from '../../../services/servicesAPI';
import { MainInput } from '../../../mainComponent/mainInput';
import { MainSelect } from '../../../mainComponent/mainSelect';
import AuthApi from '../../../services/AuthApi';
import { showToast } from '../../Toaster';
import { MainButtonInput } from '../../../mainComponent/mainButtonInput';
import TreatmentApi from '../../../services/TreatmentApi';
import { validateForm } from '../../Dashboard-card/validateForm';
import { useRecoilState } from 'recoil';
import { setAppointmentId } from '../../../recoil/atom/setAppointmentId';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

export default function CreateTreatment(props) {
    const { doctorId, reportId } = props
    const [appointmentId, setappointmentId] = useRecoilState(setAppointmentId);
    const [saveServices, setSaveservices] = useState([])
    const [services, setGetServices] = useState([]);
    const [saveTooth, setSaveTooth] = useState([]);
    const [errors, setErrors] = useState({})
    const [doctors, setDoctors] = useState(null)
    const [selectedTeeth, setSelectedTeeth] = useState([])
    const [selectedValue, setSelectedValue] = useState(null)
    const [date, setDate] = useState(new Date())
    const { getDrInfo } = AuthApi()
    const { getServices } = ServicesApi()
    const { CreateTreatments } = TreatmentApi()
    const [selectedDate, setSelectedDate] = useState(dayjs());

    useEffect(() => {
        getservicesData();
        generateOctalCombinations();
        doctorData()
    }, [])

    const handleDateChange = (newValue) => {
        setSelectedDate(newValue);
    };


    const doctorData = () => {
        getDrInfo({ doctorId })
            .then((res) => {
                const doctorList = res.result[0]["doctorList"]
                // const filterDoctors = doctorList.filter((item) => {
                //     return item.clinics.some(clinic => clinic.id === clinicId);
                // });
                doctorList.push(res.result[0])
                setDoctors(doctorList)
            })
    }

    const handleDataSave = (e, selectedData) => {
        e.preventDefault()
        setSaveservices(selectedData)
    }

    const getservicesData = () => {
        getServices(doctorId)
            .then((result) => {
                setGetServices(result.data)
            })
    };

    const handleTooths = (event, selectedValue) => {
        event.preventDefault()
        setSaveTooth(selectedValue)
    }

    const handleChange = (e) => {
        const value = e.target.value;
        setSelectedValue(value);
    };

    function generateOctalCombinations() {
        let combinations = [];
        for (let x = 1; x <= 4; x++) {
            for (let y = 1; y <= 8; y++) {
                let octalX = x.toString(16)
                let octalY = y.toString(16)
                combinations.push({
                    value: `${octalX} , ${octalY}`,
                });
            }
        }
        setSelectedTeeth(combinations)
    }

    const handleDate = (date) => {
        setDate(date)
    }
    const DataSave = () => {
        const validation = validateForm({
            saveServices,
            date,
            saveTooth,
            selectedValue,
        });

        if (!validation.formIsValid) {
            setErrors(validation.errors);
            return;
        }
        const bodyData = {
            reportId: reportId,
            parentId: doctorId,
            doctorId: selectedValue,
            appointmentId: appointmentId,
            treatmentName: saveServices.name,
            planDate: selectedDate,
            treatmentPrice: saveServices.fees,
            treatmenttooth: saveTooth,
            status: "Pending",
        }
        CreateTreatments(bodyData)
            .then((response) => {
                props.onSubmit()
                showToast("Saved Successfully!")
            })
    }

    return (
        <>
            <div className='row' >
                <div className='left col-md-6'>
                    <label className='left font_weight'>Treatments *</label>
                    <Autocomplete
                        // id={WokType._id}
                        disablePortal={true}
                        disableClearable
                        disableCloseOnSelect
                        value={saveServices.name}
                        onChange={handleDataSave}
                        getOptionLabel={(data) => `${data.name}`}
                        options={services}
                        renderOption={(props, option) => (<Box component="li" sx={{ mr: 2, flexShrink: 0 }} {...props}>{option.name}</Box>)}
                        renderInput={(params) =>
                            <TextField {...params}
                                label="Choose service"
                            />}
                    />
                    {errors.treatment && <div className="validation">{errors.treatment}</div>}
                </div>
                <div className=' col-md-6'>
                    <label className="left font_weight">Price</label>
                    <MainInput
                        type="text"
                        value={saveServices.fees || ''}
                        className="form-control"
                        placeholder='Price'
                        onChange={(e) => setSaveservices({ ...saveServices, fees: e.target.value })}
                        name="price"
                    />
                    {errors.fees && <div className="validation">{errors.fees}</div>}
                </div>
            </div>
            <div className='row'>
                <div className="col-md-6">
                    <div className=' '>
                        <label className="font_weight">Tooth Number *</label>
                        <Autocomplete
                            multiple={true}
                            disablePortal={true}
                            disableClearable
                            disableCloseOnSelect
                            value={saveTooth}
                            onChange={handleTooths}
                            getOptionLabel={(data) => `${data}`}
                            options={selectedTeeth.map((n) => `${n.value}`)}
                            renderOption={(props, option) => (<Box component="li" sx={{ mr: 2, flexShrink: 0 }} {...props}>{option}</Box>)}
                            noOptionsText={"tooth not available"}
                            renderInput={(params) =>
                                <TextField {...params}
                                    label="Select Tooth"
                                />
                            }
                        />
                    </div>
                </div>
                {errors.tooth && <div className="validation">{errors.tooth}</div>}
                <div className="col-md-6">
                    <label className="font_weight">Select Doctor *</label>
                    <MainSelect className='appColor btn_sub' value={selectedValue} onChange={handleChange}>
                        <option value="">Assign Doctor</option>
                        {doctors && doctors.map((item, index) => (
                            <option
                                key={item._id}
                                value={item._id}
                                className="form-control">
                                {item.name}
                            </option>
                        ))}
                    </MainSelect>
                    {errors.doctor && <div className="validation">{errors.doctor}</div>}
                </div>
            </div>
            {/* <div className='col-md-6'>
                <div className="mt-2">
                    <MainMuiPickers
                        name="startYear"
                        value={date}
                        onChange={handleDate}>Select Date
                    </MainMuiPickers>
                </div>
                {errors.date && <div className="validation">{errors.date}</div>}
            </div> */}
            <div className="col-md-6 calendar-container">
                <label className="font_weight mt-3">Plan Date *</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateCalendar
                        value={selectedDate}
                        onChange={handleDateChange} />
                </LocalizationProvider>
            </div>
            <div className="text-right mt-2">
                <MainButtonInput onClick={DataSave}>Save Treatments</MainButtonInput>
            </div>
        </>
    )
}