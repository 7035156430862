import React, { useState, useEffect } from 'react';
import { Outlet, useParams, useNavigate, Link } from "react-router-dom";
import UserLinks from '../partial/uselinks';
import { setHelperData } from '../../../recoil/atom/setHelperData';
import { useRecoilState } from "recoil";
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { Wrapper } from '../../../mainComponent/Wrapper';
import days from '../../../data/days.json';
import Toaster from '../../Toaster';
import "react-toastify/dist/ReactToastify.css";
import { ExpandMore } from '@mui/icons-material';
import { MainNav } from '../../../mainComponent/mainNav';
import { Modal } from 'react-bootstrap';
import TouristAppointmentsApi from '../../../services/TouristAppointmentsApi';
import { MainInput } from '../../../mainComponent/mainInput';
import { MainSelect } from '../../../mainComponent/mainSelect';
import { MainButtonInput } from '../../../mainComponent/mainButtonInput';
import axios from 'axios';
import { API } from '../../../config';

export default function UpdatePackage() {
    const { doctorId, packageId } = useParams();
    const [helpersData, setHelpersData] = useRecoilState(setHelperData)
    const [activity, setActivity] = useState([]);
    const [daysData, setDaysData] = useState([]);
    const [selectedDays, setSelectedDays] = useState([]);
    const [packageData, setPackageData] = useState([]);
    const [packages, setPackages] = useState([]);
    const [showData, setShowData] = useState(false);
    const { updatePackage, getActivities, getPackageById, GetAllPackageTypes } = TouristAppointmentsApi()
    const [documents, setDocuments] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedItem, setSelectedItem] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [file, setFile] = useState(null);
    const navigate = useNavigate();

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setPackageData({ ...packageData, [name]: value });
    }

    useEffect(() => {
        getActivityTypes();
        getAllPackages()
    }, [])

    const getActivityTypes = () => {
        getPackageById(packageId)
            .then((packageResponse) => {
                setPackageData(packageResponse)
                setSelectedItem(packageResponse.package_price)
                setSelectedDays(packageResponse.package_duration || 0);
                setDaysData(packageResponse.package_activities || []);
                if (packageResponse["package_images"]) {
                    setDocuments(packageResponse["package_images"])
                }
                setShowData(true);
            })
        getActivities()
            .then((res) => {
                setActivity(res)
            })
    };

    const handleDescriptionChange = (event, index) => {
        const value = event.target.value;
        const updatedDaysData = [...daysData];
        updatedDaysData[index].description = value;
        setDaysData(updatedDaysData);
    };

    const handleActivityChange = (event, index) => {
        const value = event.target.value;
        const updatedDaysData = [...daysData];
        updatedDaysData[index].activity = value;
        setDaysData(updatedDaysData);
    };

    const handleDaysChange = (e) => {
        const value = parseInt(e.target.value, 10);
        setSelectedDays(value);

        const updatedDaysData = Array.from({ length: value }, (_, index) => ({
            activity: daysData[index]?.activity || "",
            description: daysData[index]?.description || "",
        }));
        setDaysData(updatedDaysData);
        setShowData(true);
    };

    const openModal = (url) => {
        setSelectedImage(url);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedImage(null);
    };

    const handleFileChange = (event) => {
        setFile(event.target.files);
    };

    const getAllPackages = () => {
        GetAllPackageTypes()
            .then((res) => {
                setPackages(res)
            })
    }

    const handleDelete = async (url) => {
        try {
            await axios.delete(`${API}/packageimg/delete-file`, { data: { imageUrl: url } });
            getActivityTypes();
            alert("File remove successfully")
        } catch (error) {
            console.error("Error deleting file", error);
        }
    };

    const handleSelectData = (e) => {
        e.preventDefault();
        const value = e.target.value
        setSelectedItem(value)
    }

    async function handleSubmit(e) {
        e.preventDefault()
        const bodyData = {
            "doctorId": doctorId,
            "packagename": packageData.Package_name,
            "excerpt": packageData.package_excerpt,
            "description": packageData.package_description,
            "packageprice": selectedItem,
            "activitiesperday": daysData,
            "noofdays": selectedDays,
        }
        await updatePackage(packageId, bodyData)
            .then(async (res) => {
                const formData = new FormData();
                if (file) {
                    Array.from(file).forEach((singleFile) => formData.append("photo", singleFile));
                    try {
                        await axios.post(`${API}/updatepackageImg/${packageId}`, formData, {
                            headers: { "Content-Type": "multipart/form-data" }
                        });
                        alert("file uploaded successfully")
                    } catch (error) {
                        console.error("Error uploading files", error.response ? error.response.data : error.message);
                    }
                }
            })
        navigate(`/tourism/${doctorId}`)
    }

    return (
        <Wrapper>
            <MainNav>
                <ul className="clearfix ">
                    <li>
                        <Link to={`/tourism/${doctorId}`}>
                            <i className="arrow_back backArrow" title="back button"></i>
                        </Link>
                    </li>
                    <li
                        className='float-none'
                        style={{ fontSize: 'inherit' }} >
                        Update Package
                    </li>
                </ul>
            </MainNav>
            <div className="row ">
                <div className='width_16'>
                    <div className='dash row'>
                        <UserLinks
                            doctorId={doctorId}
                            helperId={helpersData._id}
                            accessModule={helpersData.access_module}
                        />
                    </div>
                </div>
                <div className='width_84 common_box'>
                    <form encType='multipart/form-data' onSubmit={handleSubmit}>
                        <div className='white-box'>
                            <div className='p-2'>
                                <div className="row">
                                    <div className="col-lg-5">
                                        <label htmlFor='name' className='helperLabel font_weight float-left'>Package Name</label>
                                        <MainInput
                                            type="text"
                                            name="Package_name"
                                            value={packageData.Package_name}
                                            onChange={handleChange}
                                            placeholder="Enter Package Name">
                                        </MainInput>

                                        <div>
                                            <label htmlFor='email' className='helperLabel font_weight float-left'>Package Plan</label>
                                        </div>
                                        <MainSelect
                                            value={selectedItem}
                                            onChange={handleSelectData}
                                        >
                                            <option value="">select Plan</option>
                                            {packages && packages.map((item, index) => (
                                                <option key={index}
                                                    value={item.packageName}
                                                    className="form-control">
                                                    {item.packageName}
                                                </option>
                                            ))}
                                        </MainSelect>
                                        <div>
                                            <label htmlFor='password' className='helperLabel font_weight float-left'>Package Description</label>
                                        </div>
                                        <div>
                                            <textarea
                                                type="text"
                                                name="package_excerpt"
                                                value={packageData.package_excerpt}
                                                onChange={handleChange}
                                                className="form-control"
                                                placeholder="short description"
                                            />
                                        </div>
                                        <div>
                                            <textarea
                                                type="text"
                                                name="package_description"
                                                value={packageData.package_description}
                                                onChange={handleChange}
                                                className="form-control"
                                                placeholder="long description"
                                            />
                                        </div>
                                        <div className='mt-2 mb-2'>
                                            <label className='helperLabel font_weight float-left'>Upload Photos</label>
                                            <>
                                                <MainInput
                                                    type="file"
                                                    accept=".png, .jpg, .jpeg"
                                                    onChange={handleFileChange}
                                                    name="photo"
                                                    multiple
                                                />
                                            </>
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                        <div className="helperDiv mb-3">
                                            <label htmlFor="mobile" className="helperLabel font_weight float-left">
                                                No. Of Days
                                            </label>
                                            <MainSelect value={selectedDays} onChange={handleDaysChange}>
                                                <option value="">Select Days</option>
                                                {days &&
                                                    days.map((item, index) => (
                                                        <option key={index} value={item._id} className="form-control">
                                                            {item.number}
                                                        </option>
                                                    ))}
                                            </MainSelect>
                                        </div>
                                        {showData && daysData.length > 0 ? (
                                            <>
                                                {daysData.map((day, index) => (
                                                    <div className="helperDiv" key={index}>
                                                        <Accordion defaultActiveKey="0">
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMore />}
                                                                aria-controls="calendar-content"
                                                                id={`calendar-header-${index}`}
                                                            >
                                                                Day {index + 1}
                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                                <div className="mb-3">
                                                                    <label
                                                                        htmlFor="activity"
                                                                        className="helperLabel font_weight float-left"
                                                                    >
                                                                        Select Activity
                                                                    </label>
                                                                    <MainSelect
                                                                        value={day.activity || ""}
                                                                        onChange={(e) => handleActivityChange(e, index)}
                                                                    >
                                                                        <option value="">Select Activity</option>
                                                                        {activity &&
                                                                            activity.map((item, activityIndex) => (
                                                                                <option
                                                                                    key={activityIndex}
                                                                                    value={item.activity_name}
                                                                                    className="form-control"
                                                                                >
                                                                                    {item.activity_name}
                                                                                </option>
                                                                            ))}
                                                                    </MainSelect>
                                                                </div>
                                                                <div>
                                                                    <label
                                                                        htmlFor="description"
                                                                        className="helperLabel font_weight float-left"
                                                                    >
                                                                        Package Description
                                                                    </label>
                                                                </div>
                                                                <div>
                                                                    <textarea
                                                                        type="text"
                                                                        name="description"
                                                                        value={day.description || ""}
                                                                        onChange={(e) => handleDescriptionChange(e, index)}
                                                                        className="form-control"
                                                                        placeholder="Description"
                                                                    />
                                                                </div>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </div>
                                                ))}
                                            </>
                                        ) : null}

                                    </div>
                                </div>

                                <h6 align="left" className='mb-2'>Uploaded Photos:</h6>
                                <div className='row'>
                                    {documents.map((url, index) => (
                                        <div key={index}>
                                            <span className='removeButton' onClick={() => handleDelete(url)}> X </span>
                                            <img
                                                src={url}
                                                alt={`Uploaded file ${index + 1}`}
                                                className='uploadedimg'
                                                onClick={() => openModal(url)}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="text-center add_top_30 pb-2">
                                <MainButtonInput>Save</MainButtonInput>
                            </div>
                        </div>
                        {isModalOpen && (
                            <Modal show={isModalOpen} onHide={closeModal}>
                                <Modal.Header closeButton onClick={(e) => e.stopPropagation()}>
                                </Modal.Header>
                                <Modal.Body>
                                    <img src={selectedImage} alt="Selected Image" className="modal-image" />
                                </Modal.Body>
                            </Modal>
                        )}
                    </form>
                    <div className="row float-right toaster">
                        <Toaster />
                    </div>
                </div>
            </div>
            <Outlet />
        </Wrapper>

    )
}